import { IUser } from "../Models/User";

export function getMockUser(): IUser {
    return {
        id: "1234",
        username: "mockuser",
        firstName: "Mock",
        lastName: "User",
        email: "mock.user@path.com",
        tenantId: "1234",
        settings: {
            language: "en",
            theme: "dark",
            streaming: true
        },
        products: ["ATO", "Expert", "Conlfix"],
        roles: ["AskMaddi.Admin", "AskMaddi.User"]
    }
}