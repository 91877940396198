import { Observable } from "rxjs";
import { AIMessage, ObservableAIMessage, IAIMessageChunk } from "../Models/Message";

export const DEFAULT_ERROR_MESSAGE_CONTENT = (
    `Sorry, I'm having some trouble answering your question. If this issue persists, please contact the site administrator.`
);

export const DEFAULT_ERROR_MESSAGE = new AIMessage(
    DEFAULT_ERROR_MESSAGE_CONTENT,
    new Date()
);

const streamErrorMessageContent = (): Observable<IAIMessageChunk> => {
    const tokens = DEFAULT_ERROR_MESSAGE_CONTENT.split(" ");
    const chunks = tokens.map((token) => {
        return {
            content: token,
            datetime: new Date()
        }
    });

    return new Observable<IAIMessageChunk>((observer) => {
        let chunkTime = 80;
        let totalTime = 0;
    
        const incrementTime = () => {
          totalTime += Math.floor(Math.random() * chunkTime);
          return totalTime;
        }
      
        // send the chunks
        chunks.forEach((chunk) => {
          chunk.content = chunk.content + ' ';
          setTimeout(() => observer.next(chunk), incrementTime());
        })
      
        // complete the observable
        setTimeout(() => observer.complete(), incrementTime()*10);
      });
} 

export const streamDefaultErrorMessage = () => { return new ObservableAIMessage(streamErrorMessageContent())}