import { Observable, from } from "rxjs";
import { AIMessage, HumanMessage, IAIMessageChunk, ObservableAIMessage } from "../Models/Message";
import { BaseAPI } from "./base";
import { getMockMessage, getMockMessageStream } from "../Mocks/messages";
import { DEFAULT_ERROR_MESSAGE, streamDefaultErrorMessage } from "../Constants/AIMessages";


export class ChatAPI extends BaseAPI {

    public async chat(conversationId:string, index:string, message:HumanMessage): Promise<AIMessage> {
        if (this.isMock) {
            return this.mockFetch(() => getMockMessage(message.content), 3000)
        }

        let payload = {
            conversationId:conversationId,
            index:index,
            message:message.content,
        }
        
        try {
            let response = await this.post(`${this.apiBase}/chat`, payload, "json");
            return new AIMessage(
                response.content,
                response.datetime,
                response
            );

        } catch (error) {
            console.error(`Error in chat method: ${error}`);
            return DEFAULT_ERROR_MESSAGE
        }
    }

    public async streamChat(conversationId:string, index:string, message:HumanMessage): Promise<ObservableAIMessage> {
        if (this.isMock) {
            return new ObservableAIMessage(await this.mockFetch(() => getMockMessageStream(message.content), 3000));
        }

        let payload = {
            conversationId:conversationId,
            index:index,
            message:message.content,
        }

        try {

            let stream: Observable<IAIMessageChunk> = await this.post(`${this.apiBase}/chat/stream`, payload, "stream");
            return new ObservableAIMessage(stream);
        } catch (error) {
            console.error(`Error in streamChat method: ${error}`);
            return streamDefaultErrorMessage();
        }
    }

}