import './MessageAvatar.scss';
import Image from 'react-bootstrap/Image';


export default function MessageAvatar({messageType: prefix}:{messageType:string}) {
    const fileNameSrc = window.location.origin;

    return (
        <div className='avatar'>
            <Image className={`MessageAvatar ${prefix.toLowerCase()}-avatar`} src={`${fileNameSrc}/logo512.png`}></Image>
        </div>  
    )

}
