import { useReducer } from "react";
import { IConversation } from "../Models/Conversation";
import { IMessage } from "../Models/Message";

// Define the initial state of the reducer
export type State = {
    newConversationId: string | undefined;
    conversations: IConversation[];
    selectedConversation: IConversation | undefined;
    messages: IMessage[];
    pendingPromises: Promise<any>[];
    isConversationsLoading: boolean;
    isMessagesLoading: boolean;
};

// Define the reducers interface
export type Action =
    | { type: 'SET_NEW_CONVERSATION_ID'; payload: string | undefined }
    | { type: 'SET_CONVERSATIONS'; payload: IConversation[] }
    | { type: 'SET_SELECTED_CONVERSATION'; payload: IConversation | undefined }
    | { type: 'SET_MESSAGES'; payload: IMessage[] }
    | { type: 'ADD_PENDING_PROMISE'; payload: Promise<any> }
    | { type: 'REMOVE_PENDING_PROMISE'; payload: Promise<any> }
    | { type: 'ADD_MESSAGE'; payload: IMessage }
    | { type: 'ADD_CONVERSATION'; payload: IConversation }
    | { type: 'SET_IS_CONVERSATIONS_LOADING'; payload: boolean }
    | { type: 'SET_IS_MESSAGES_LOADING'; payload: boolean };
  
// Define the reducer function
export function chatReducer(state: State, action: Action): State {
    switch (action.type) {
        case 'SET_NEW_CONVERSATION_ID':
            return { ...state, newConversationId: action.payload };
        case 'SET_CONVERSATIONS':
            return { ...state, conversations: action.payload };
        case 'SET_SELECTED_CONVERSATION':
            return { ...state, selectedConversation: action.payload };
        case 'SET_MESSAGES':
            return { ...state, messages: action.payload };
        case 'ADD_PENDING_PROMISE':
            return { ...state, pendingPromises: [...state.pendingPromises, action.payload] };
        case 'REMOVE_PENDING_PROMISE':
            return { ...state, pendingPromises: state.pendingPromises.filter(promise => promise !== action.payload) };
        case 'ADD_MESSAGE':
            return { ...state, messages: [...state.messages, action.payload] };
        case 'ADD_CONVERSATION':
            return { ...state, conversations: [...state.conversations, action.payload] };
        case 'SET_IS_CONVERSATIONS_LOADING':
            return { ...state, isConversationsLoading: action.payload };
        case 'SET_IS_MESSAGES_LOADING':
            return { ...state, isMessagesLoading: action.payload };
        default:
            throw new Error(`Unhandled action type: ${action}`);
    }
}
  