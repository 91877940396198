import './PendingResponseMessage.scss';
import MessageAvatar from '../MessageAvatar';
import MessageWrapper from '../MessageWrapper';

export default function PendingResponseMessage() {
    return (
        <div className="Message">
            <div className='avatar'>
                <MessageAvatar messageType="ai"></MessageAvatar>
            </div>
            <div>
                <div className={`MessageBubble ai-message`}>
                    <div className="MessageContent">
                        <div className="LoadingAnimationCentered">
                            <div className="LoadingAnimation">
                                <span className="LoadingAnimation__dot"></span>
                                <span className="LoadingAnimation__dot"></span>
                                <span className="LoadingAnimation__dot"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}