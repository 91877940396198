// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MessageFollowUps {
  padding: 0;
  container: continue-journey/inline-size;
}
.MessageFollowUps .accordion .accordion-header:before {
  content: "\\e0a4";
}

@container continue-journey (min-width: 768px) {
  .btn-group-vertical {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .btn-group-vertical button {
    height: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Message/MessageFollowUpQuestions/MessageFollowUpQuestions.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,uCAAA;AACJ;AACI;EACI,gBAAA;AACR;;AAGA;EACI;IACI,aAAA;IACA,8BAAA;EAAN;EAEM;IACI,YAAA;EAAV;AACF","sourcesContent":[".MessageFollowUps {\r\n    padding: 0;\r\n    container: continue-journey / inline-size;\r\n\r\n    .accordion .accordion-header:before {\r\n        content: \"\\e0a4\";\r\n    }\r\n}\r\n\r\n@container continue-journey (min-width: 768px) {\r\n    .btn-group-vertical {\r\n        display: grid;\r\n        grid-template-columns: 1fr 1fr;\r\n\r\n        button {\r\n            height: 100%;\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
