import { IPublicClientApplication, PopupRequest, RedirectRequest } from "@azure/msal-browser";


export class AuthenticationService {
    readonly msalInstance: IPublicClientApplication;
    readonly request: PopupRequest | RedirectRequest;

    constructor(msalInstnace:IPublicClientApplication, request: PopupRequest | RedirectRequest ) {
        this.msalInstance = msalInstnace;
        this.request = request;
    }

    public async login(loginMethod: "popup" | "redirect" = "redirect") {
        if (loginMethod == "popup") {
            return await this.msalInstance.loginPopup(this.request);
        } else {
            return await this.msalInstance.loginRedirect(this.request);
        }
        
    }

    public async logout(loginMethod: "popup" | "redirect" = "redirect") {
        if (loginMethod == "popup") {
            return await this.msalInstance.logoutPopup(this.request);
        } else {
            return await this.msalInstance.logoutRedirect(this.request);
        }
    }

    public async getAccessTokenSilent(): Promise<string> {
        return await this.msalInstance.acquireTokenSilent(this.request).then((result) => result.accessToken);
    }

    public async getAccessTokenPopup(): Promise<string> {
        return await this.msalInstance.acquireTokenPopup(this.request).then((result) => result.accessToken);
    }

    public async getAccessTokenRedirect(): Promise<void> {
        return await this.msalInstance.acquireTokenRedirect(this.request);
    }

    public getActiveAccount() {
        return this.msalInstance.getActiveAccount();
    }

    public clearCache() {
        return this.msalInstance.clearCache();
    }
}