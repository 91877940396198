import { useEffect, useRef, useState } from "react";
import { IStreamedAIMessage } from "../../../Models/Message";
import { AIMessageProps } from "./AIMessage";
import MessageWrapper from "../MessageWrapper";
import AIMessageMetadata from "../AIMessageMetadata";
import MessageContent from "../MessageContent";
import PendingResponseMessage from "../PendingResponseMessage";
import { CopyToClipboardControl } from "../../Controls/CopyToClipboard";


export interface StreamedAIMessageProps extends AIMessageProps {
    message: IStreamedAIMessage;
    tokenDelay?: number;    
}

export default function StreamedAIMessage({ message, tokenDelay=50, onFollowUpQuestionClick }: StreamedAIMessageProps) {
    const [timeoutBuffer, setTimeoutBuffer] = useState<number>(0);
    const [contentQueue, setContentQueue] = useState<string[]>([]);
    const [content, setContent] = useState<string>(message.isStopped ? message.content : "");
    const [isTokenRecieved, setIsTokenRecieved] = useState<boolean>(false);
    const [isStopped, setIsStopped] = useState<boolean>(message.isStopped);
    const [streamSubscription, setStreamSubscription] = useState<any>(null);

    useEffect(() => {
        if (!streamSubscription && !isStopped && !message.isStopped) {
            const subscription = message.stream$.subscribe({
                next: (chunk) => {
                    if (!isTokenRecieved && chunk.content) setIsTokenRecieved(true);
                    setContentQueue(prevQueue => [...prevQueue, chunk.content ? chunk.content : ""]);
                },
                complete: () => {
                    setIsStopped(true);
                    streamSubscription?.unsubscribe();
                }
            });
            setStreamSubscription(subscription);
        }
    }, [streamSubscription]);

    useEffect(() => {
        if (contentQueue.length > 0) {
            let dqContent = contentQueue[0];
            setContentQueue(prevQueue => prevQueue.slice(1));
            setTimeoutBuffer(prevBuffer => prevBuffer + tokenDelay)
            setTimeout(() => {
                setContent(prevContent => prevContent + dqContent)
                setTimeoutBuffer(prevBuffer => prevBuffer - tokenDelay)
            }, timeoutBuffer);
        }
    }, [contentQueue]);

    return (
        <>
            {
                !isTokenRecieved ? <PendingResponseMessage /> :
                <MessageWrapper message={message}>
                    <CopyToClipboardControl textToCopy={content} />
                    <MessageContent content={content} messageType={message.type} />
                    {isStopped && timeoutBuffer == 0 && <AIMessageMetadata message={message} onFollowUpQuestionClick={onFollowUpQuestionClick} />}
                </MessageWrapper>
            }
        </>
    )
}