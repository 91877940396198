// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MessageAvatar {
  max-height: 3.75vmax;
  background-size: cover !important;
  border-radius: 50%;
  display: inline-block;
}

.ai-avatar,
.system-avatar {
  float: left;
}

.human-avatar {
  float: right;
}`, "",{"version":3,"sources":["webpack://./src/Components/Message/MessageAvatar/MessageAvatar.scss"],"names":[],"mappings":"AAAA;EACC,oBAAA;EAEA,iCAAA;EACA,kBAAA;EACA,qBAAA;AAAD;;AAIA;;EAEI,WAAA;AADJ;;AAIA;EACI,YAAA;AADJ","sourcesContent":[".MessageAvatar {\r\n\tmax-height: 3.75vmax;\r\n\t// width: 40px;\r\n\tbackground-size: cover !important;\r\n\tborder-radius: 50%;\r\n\tdisplay: inline-block;\r\n\t// align-items: center;\r\n}\r\n\r\n.ai-avatar,\r\n.system-avatar {\r\n    float: left;\r\n}\r\n\r\n.human-avatar {\r\n    float: right;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
