// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HelpIcon {
  font-size: 18px;
  font-family: "viglobal";
}`, "",{"version":3,"sources":["webpack://./src/Components/Icons/Help/HelpIcon.scss"],"names":[],"mappings":"AAEA;EAWI,eAAA;EAGA,uBAAA;AAbJ","sourcesContent":["@import \"../../../Styles/variables.scss\";\r\n\r\n.HelpIcon {\r\n    // border-radius: 50px;\r\n    // cursor: pointer;\r\n    // color: #fff;\r\n    // font-weight: 300;\r\n    // text-align: center;\r\n    // align-items: center;\r\n    // width: auto;\r\n    // background: transparent;\r\n    // border: none;\r\n    // // color: $brand-color-original;\r\n    font-size: 18px;\r\n    // padding: 0 10px;\r\n    // display: flex;\r\n    font-family: 'viglobal';\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
