import './AIMessage.scss';
import { AIMessage as AIMessageModel, ObservableAIMessage } from '../../../Models/Message';
import NormalAIMessage from './NormalAIMessage';
import StreamedAIMessage from './StreamedAIMessage';

export interface AIMessageProps {
    message: AIMessageModel | ObservableAIMessage
    onFollowUpQuestionClick?: (question: string) => void
}

export default function AIMessage({ message, onFollowUpQuestionClick }: AIMessageProps) {
    if (message instanceof ObservableAIMessage && !message.isStopped) {
        return <StreamedAIMessage message={message} onFollowUpQuestionClick={onFollowUpQuestionClick} />
    } else {
        return <NormalAIMessage message={message} onFollowUpQuestionClick={onFollowUpQuestionClick} />
    }
}