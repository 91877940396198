import { useContext } from "react";
import { AskMaddiUserContext } from "../Contexts";
import { IUser } from "../Models/User";

export function useAskMaddiUser(): [IUser, (user: IUser) => void] {
    const context = useContext(AskMaddiUserContext);
    if (!context) {
      throw new Error('useAskMaddiUser must be used within an AskMaddiUserContextProvider');
    }
    const {user, setUser} = context;
    if (!user || !setUser) {
      throw new Error('useAskMaddiUser must be used within an AskMaddiUserContextProvider');
    }

    return [user, setUser]
}