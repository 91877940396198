
import { Routes, Route } from "react-router-dom";
import { ChatAppLayout, FAQUpdateLayout } from '../Layouts';
import FAQ from '../Pages/FAQ';
import Updates from '../Pages/Updates';
import { useAskMaddiUser } from "../Hooks/useAskMaddiUser";
import {IMPLEMENTED_PRODUCTS} from '../Constants/routes';
import ChatPage from "../Pages/ChatPage";
import Expert from "../Pages/Expert";

function Router(){
    const [user, setUser] = useAskMaddiUser();
    let available_products=[]
    for (let product in IMPLEMENTED_PRODUCTS){
        if (user.products.includes(product)){
            available_products.push(product);
        }
    }
    return(
        <Routes>
            <Route element={<ChatAppLayout products={available_products}/>}>
                <Route path='/' element={<ChatPage indexName={IMPLEMENTED_PRODUCTS['Expert' as keyof typeof IMPLEMENTED_PRODUCTS]}/>}></Route>
                {available_products.map((product) => <Route path={product} element={<ChatPage indexName={IMPLEMENTED_PRODUCTS[product as keyof typeof IMPLEMENTED_PRODUCTS]}/>}></Route>)}
            </Route>
            <Route  element={<FAQUpdateLayout products={available_products}/>}>
                <Route path='faq' element={<FAQ/>}/>
                <Route path='updates' element={<Updates/>}/>
            </Route>
        </Routes>
)}

export default Router;