import { IAIMessage, AIMessage, IMessageRating } from "../../../Models/Message"
import MessageReferences from "../MessageReferences"
import MessageFollowUps from "../MessageFollowUpQuestions"
import MessageRating  from "../MessageRating"
import { useAskMaddiAPI } from "../../../Hooks/useAskMaddiAPI"

export interface AIMessageMetadataProps {
    message: IAIMessage
    hidden?: boolean,
    onFollowUpQuestionClick?: (question: string) => void
}

export default function AIMessageMetadata({ message, hidden=false, onFollowUpQuestionClick }: AIMessageMetadataProps) {
    let askMaddiAPI = useAskMaddiAPI()
    const handleRatingSubmit = async (rating: IMessageRating) => {
        if (message._id && message instanceof AIMessage) {
            let success = await askMaddiAPI.Message.rateMessage(message._id, rating);
            if (success) {
                message.rating = rating;
            }
        }
    }

    return (
        <div className="AIMessageMetadata">
            <div className='additional-info-wrapper' hidden={hidden}>
                <div className='additional-info-container'>
                    { message.sourceNodes?.length ? <MessageReferences sourceNodes={message.sourceNodes}/> : <></>}
                    { message.followUpQuestions?.length ? 
                        <MessageFollowUps questions={message.followUpQuestions} onQuestionClick={onFollowUpQuestionClick || console.log }/> 
                        : <></>
                    }
                </div>
                <MessageRating initialRating={message.rating} onRatingSubmit={handleRatingSubmit} disabled={!!message.rating?.isHelpful}/>
            </div>
        </div>
    )
}