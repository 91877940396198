import { Outlet } from "react-router-dom";
import AskMaddiNavbar, { CurrentUserNavDropdown, HelpNavDropdown, IndexSelectionNavDropdown, ChatSettingsNavDropdown, HomeNav } from "../Components/Navbar";
import { IMPLEMENTED_ROUTES } from "../Constants/routes";

export interface FAQUpdateLayoutProps {
    displayIndexSelection?: boolean;
    displayHelpDropdown?: boolean;
    products:Array<string>;
}

export function FAQUpdateLayout({ products, displayIndexSelection = true, displayHelpDropdown = true }: FAQUpdateLayoutProps) {
    return (
        <>
            <AskMaddiNavbar>
                <HomeNav />
                {displayIndexSelection && <IndexSelectionNavDropdown indices={products} />}
                {displayHelpDropdown && <HelpNavDropdown />}
                <CurrentUserNavDropdown />
                <ChatSettingsNavDropdown/>
            </AskMaddiNavbar>
            <div className="App content-page">
                <header className="App-content pad-20">
                    <Outlet></Outlet>
                </header>
            </div>
        </>
    )
}