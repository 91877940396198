import './MessageFollowUpQuestions.scss';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

export interface MessageFollowUpsProps {
    questions: string[],
    onQuestionClick: (question: string) => void
}

export default function MessageFollowUps({questions, onQuestionClick}:MessageFollowUpsProps) {
    return (
        <div className='MessageFollowUps follow-up-questions'>
            <Accordion flush>
                <Accordion.Header>
                    Continue Your Journey
                </Accordion.Header>
                <Accordion.Body>
                <ButtonGroup vertical>
                    {questions.map((question, index) => (
                        <Button 
                            key={index} 
                            variant='outline-primary' 
                            onClick={() => onQuestionClick(question)}
                        >
                            {question}
                        </Button>
                    ))}
                </ButtonGroup>
                </Accordion.Body>
            </Accordion>
        </div>
    )
}