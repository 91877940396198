import { useReducer, useContext, useEffect } from "react";
import { useAskMaddiUser } from "./useAskMaddiUser";
import { chatReducer } from "../Reducers/chatReducer";
import { useConversationHistory } from "./useConversationHistory";
import { useUserConversations } from "./useUserConversations";

export function useChatReducer(indexName?:string) {
    const [user, setUser] = useAskMaddiUser();
    const [state, dispatch] = useReducer(chatReducer, {
        newConversationId: undefined,
        conversations: [],
        selectedConversation: undefined,
        messages: [],
        pendingPromises: [],
        isConversationsLoading: false,
        isMessagesLoading: false,
    });
    const [conversations, isConversationsLoading, setConversations] = useUserConversations(user.id, indexName);
    const [messages, isMessagesLoading, setMessages] = useConversationHistory(
        state.selectedConversation?.id, 
        state.newConversationId === state.selectedConversation?.id
    );

    useEffect(() => {
        dispatch({ type: 'SET_CONVERSATIONS', payload: conversations });
    }, [conversations]);

    useEffect(() => {
        dispatch({ type: 'SET_MESSAGES', payload: messages });
    }, [messages]);

    useEffect(() => {
        dispatch({ type: 'SET_IS_CONVERSATIONS_LOADING', payload: isConversationsLoading });
    }, [isConversationsLoading]);

    useEffect(() => {
        dispatch({ type: 'SET_IS_MESSAGES_LOADING', payload: isMessagesLoading });
    }, [isMessagesLoading]);

    return [state, dispatch] as const;
}