import React, { useDebugValue } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { AuthError, EventMessageUtils, IPublicClientApplication } from "@azure/msal-browser";
import { AskMaddiAPIContext } from '..';
import { AskMaddiUserContext } from '../AskMaddiUserContext';
import { AskMaddiAPI } from '../../API';
import { AuthenticationServiceContext } from '..';
import { AuthenticationService } from '../../Auth/AuthenticationClient';
import { IUser } from '../../Models/User';
import { LoadingPage } from '../../Pages/Loading';

// MSAL imports
import { MsalProvider } from '@azure/msal-react';
import { 
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
  SilentRequest,
  PopupRequest,
  RedirectRequest
} from "@azure/msal-browser";
import { AskMaddiLoginPage, AskMaddiLoginErrorPage } from '../../Pages';
import { error } from 'console';

declare function aptrinsic(...args: any[]): void;

interface AskMaddiContextProviderProps {
  msalInstance: IPublicClientApplication;
  loginRequest: PopupRequest | RedirectRequest;
  children: React.ReactNode;
}

export const AskMaddiProvider: React.FC<AskMaddiContextProviderProps> = ({ msalInstance, loginRequest, children }) => {
  const authenticationService = new AuthenticationService(msalInstance, loginRequest);
  const askMaddiAPIBase = process.env.REACT_APP_API_ORIGIN;
  const askMaddiAPI = new AskMaddiAPI(askMaddiAPIBase, authenticationService);
  
  const [account, setAccount] = useState(authenticationService.getActiveAccount());
  const [user, setUser] = useState<IUser | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [loginError, setLoginError] = useState<Error | undefined>(undefined);

  msalInstance.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
        setAccount(account);
      } else if (event.eventType === EventType.LOGOUT_SUCCESS) {  
        msalInstance.setActiveAccount(null);
        setAccount(null);
        setUser(undefined);
      } else if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
        setAccount(null);
        setUser(undefined);
        authenticationService.msalInstance.setActiveAccount(null);
        authenticationService.clearCache();
      }
      

      if (event.error instanceof AuthError) {
        console.error(event.error);
      }
  });

  useEffect(() => {
    if (account) {
      const validateUser = async () => {
        setLoading(true);
        const user = await askMaddiAPI.User.aderantIdentitySignupSignin();
        if (user) {
          if(user instanceof Error) {
            setLoginError(user);
          }
          else {
            setUser(user);
          }
        } else {
          var userNotFound = new Error("User not found. If you are seeing this message, please contact the administrator.");
          setLoginError(userNotFound)
          throw userNotFound;
        }
        setLoading(false);
      }
      try {
        validateUser();
      } 
      catch (error) {
        console.log(error);
      }
    }  
  }, [account]);

  return (
    <>

      {loading && !user && account ? <LoadingPage /> :
      <MsalProvider instance={msalInstance}>
        <AuthenticationServiceContext.Provider value={authenticationService}>
          <AskMaddiAPIContext.Provider value={askMaddiAPI}>
            <AskMaddiUserContext.Provider value={{user, setUser}}>
              {account ? children : <AskMaddiLoginPage />}
            </AskMaddiUserContext.Provider>
          </AskMaddiAPIContext.Provider>
        </AuthenticationServiceContext.Provider>
      </MsalProvider>
      }
    </>
  );
};
