import './ArrowIcon.scss';

export interface ArrowDownIconProps {
    direction: 'up' | 'down' | 'left' | 'right'
}


function ArrowDownIcon ({direction}: ArrowDownIconProps) {
    switch (direction) {
        case 'up': 
            return <div className='icon LongArrowIcon up'>V</div>

        case 'down': 
            return <div className='icon LongArrowIcon down'>T</div>

        case 'left':
            return <div className='icon LongArrowIcon left'>&#xe003;</div>

        case 'right':
            return <div className='icon LongArrowIcon right'>U</div>
    }
}


export default ArrowDownIcon;