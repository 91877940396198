
export interface ControlsWrapperProps {
    children: React.ReactNode;
    className?: string;
}

export function ControlsWrapper({ children, className }: ControlsWrapperProps) {
    return (
        <div className={`controls-wrapper ${className}`}>
            {children}
        </div>
    );
}