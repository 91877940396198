import './FlyoutToggleButton.scss';
import { ChevronIcon } from "../../Icons";

export interface FlyoutToggleButtonProps {
    onClick: () => void;
    isExpanded: boolean;
}

export function FlyoutToggleButton({ onClick, isExpanded }: FlyoutToggleButtonProps) {
    return (
        <div className="btn-flyout-toggle" onClick={onClick}>  
            <ChevronIcon variant={isExpanded ? 'opened' : 'closed'}/>
        </div>
    )
}