import { NavDropdown } from "react-bootstrap";
import { useAskMaddiUser } from "../../../Hooks/useAskMaddiUser";
import { useAskMaddiAuthenticationService } from "../../../Hooks/useAuthenticationService";

export interface CurrentUserNavDropdownProps {
}


export function CurrentUserNavDropdown(props: CurrentUserNavDropdownProps) {
    const [user, setUser] = useAskMaddiUser();
    const authenticationService = useAskMaddiAuthenticationService();

    const handleLogout = async (e: React.MouseEvent) => {
        return await authenticationService?.logout('redirect');
    }

    return (
        <NavDropdown title={`${user.firstName} ${user.lastName}`} id="CurrentUserNavDropdown" className="CurrentUserNavDropdown">
            <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
        </NavDropdown>
    );
}
