import './MessageBubble.scss';

export interface MessageBubbleProps {
    children?: React.ReactNode
    messageType: "human" | "ai" | "system";
}


export default function MessageBubble({ children, messageType }: MessageBubbleProps) {
    return (
        <div className={`MessageBubble ${messageType.toLowerCase()}-message`}>
            {children}
        </div>
    );
}