import './MessageRating.scss';
import { useState } from 'react';
import { IMessageRating } from '../../../Models/Message';
import { MessageRatingFeedbackForm } from './MessageRatingFeedbackForm';

export interface MessageRatingProps {
    onRatingSubmit: (rating: IMessageRating) => void
    initialRating?: IMessageRating
    disabled?: boolean
}

export default function MessageRating({ initialRating, onRatingSubmit, disabled = false }: MessageRatingProps) {
    const [isHelpful, setHelpful] = useState<"yes" | "no" | undefined>(initialRating?.isHelpful);
    const [submitted, setSubmitted] = useState<boolean>(disabled);

    const handleHelpfulnessButtonClick = (rating: "yes" | "no") => {
        if (isHelpful == rating) {
            setHelpful(undefined);
            return;
        }

        setHelpful(rating);
    }

    const handleRatingSubmit = (rating: IMessageRating) => {
        setSubmitted(true);
        onRatingSubmit(rating);
    }

    return (
        <div className='MessageRating'>
            <div className='MessageRatingIsHelpfulSelection'>
                {submitted ? <p>Thank you for your feedback!</p> : <p>Was this answer helpful?</p> } 
                <div className='controls-wrapper'>
                    <button className={`message-rating-yes-btn ${isHelpful == "yes" ? "clicked" : ""}`} onClick={() => handleHelpfulnessButtonClick("yes")} disabled={submitted} hidden={submitted && isHelpful != "yes"}/>
                    <button className={`message-rating-no-btn ${isHelpful == "no" ? "clicked" : ""}`} onClick={() => handleHelpfulnessButtonClick("no")} disabled={submitted} hidden={submitted && isHelpful != "no"}/>
                </div>
            </div>
            <MessageRatingFeedbackForm isHelpful={isHelpful} onSubmit={handleRatingSubmit} hidden={submitted || !isHelpful} />
        </div>
    )
}