import { NavDropdown } from "react-bootstrap";
import { HelpIcon } from "../../Icons";

export interface IHelpNavDropdownProps {
}


export function HelpNavDropdown(props: IHelpNavDropdownProps) {
    return (
    <NavDropdown title="Info" id="HelpNavDropdown" className="HelpNavDropdown">
        <NavDropdown.Item href="/faq" className="HelpNavDropdownItem" target="_blank">
            FAQ
        </NavDropdown.Item>
        <NavDropdown.Item href="/updates" className="UpdatesNavDropdownItem" target="_blank">
            Release Notes
        </NavDropdown.Item>
    </NavDropdown>
    );
}