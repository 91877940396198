import './MessageContainer.scss';
import React, { useEffect, useRef, ForwardRefRenderFunction } from 'react';
import { IMessage } from '../../../Models/Message';
import Message from '../../Message/Message';
import PendingResponseMessage from '../../Message/PendingResponseMessage';

export interface MessageContainerProps {
    messages: Array<IMessage>,
    isThinking: boolean,
    onFollowUpQuestionClick: (question: string) => void,
    lastMessageRef?: React.RefObject<HTMLDivElement>
    ref?: React.RefObject<HTMLDivElement>
}

const MessageContainer: ForwardRefRenderFunction<HTMLDivElement, MessageContainerProps> = (
    { messages, isThinking, onFollowUpQuestionClick, lastMessageRef },
    ref
) => {
    return (
        <div className="MessageContainer" ref={ref}>
            <ul className='chat-history'>
                {messages.map((msg, index) => (
                    <div ref={index === messages.length - 1 && !isThinking ? lastMessageRef : null} key={index}>
                        <Message message={msg} onFollowUpQuestionClick={onFollowUpQuestionClick} />
                    </div>
                ))}

                {isThinking && (
                    <div ref={lastMessageRef} key={Number.MAX_SAFE_INTEGER}>
                        <PendingResponseMessage />
                    </div>
                )}
            </ul>
        </div>
    )
}

export default React.forwardRef(MessageContainer);