import './ChatWindow.scss';
import { useEffect } from 'react';
import useAutoScroll from '../../../Hooks/useAutoScroll';
import ChatWindowTitle from '../ChatWindowTitle';
import MessageContainer from '../MessageContainer';
import MessageInputArea from '../MessageInputArea';
import { IMessage } from '../../../Models/Message';
import { LoadingAnimation } from '../../Animations/Loading';
import { ControlsWrapper } from '../../Controls/ControlsWrapper';
import ArrowButton from '../../Buttons/ArrowButton';


interface ChatWindowProps {
    messages: IMessage[];
    isThinking: boolean;
    onMessageSend: (message: string) => void;
    onNewConversation: () => void;
    isLoading?: boolean;
}

function ChatWindow({ messages, onMessageSend, onNewConversation, isThinking, isLoading=false}: ChatWindowProps) {
    const [scrollListenerRef, lastMessageRef, autoScroll, canScrollDown] = useAutoScroll();
    
    const handleScrollButtonClick = () => {
        lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
    }

    useEffect(() => {
        if (autoScroll) {
            lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
        }
    }, [messages, isThinking]);

    return (
        <div className="ChatWindow maddi-container">
            <ChatWindowTitle title=""/>
            {
                isLoading
                ? <LoadingAnimation/>
                : <MessageContainer 
                        messages={messages} 
                        isThinking={isThinking} 
                        onFollowUpQuestionClick={onMessageSend}
                        ref={scrollListenerRef}
                        lastMessageRef={lastMessageRef}
                    />
            }
            <ControlsWrapper className='message-input'>
                { canScrollDown && <ArrowButton direction='down' floating onClick={handleScrollButtonClick} /> }
                <MessageInputArea
                    messageInputPlaceholder={messages.filter(m => m.type == "human").length == 0 ? 'Enter a prompt...' : 'Enter your follow-up question here...'}
                    onMessageSend={onMessageSend}
                    onNewConversation={onNewConversation}
                    pastMessages={messages.filter(m => m.type == 'human').map(m => m.content)}
                />
            </ControlsWrapper>
        </div>
    )
}

export default ChatWindow;
