import './ArrowButton.scss'
import { ArrowIcon } from "../../Icons"

export interface LongArrowButtonProps {
    onClick: () => void
    direction: 'up' | 'down' | 'left' | 'right'
    floating?: boolean
}

export default function ArrowButton({ onClick, direction, floating }: LongArrowButtonProps) {
    return (
        <button className={`ArrowButton ${direction} ${floating ? 'floating' : ''}`} onClick={onClick}>
            <ArrowIcon direction={direction} />
        </button>
    )
}
