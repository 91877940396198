// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MessageReferences {
  padding: 0;
}
.MessageReferences .accordion .accordion-header:before {
  content: "\\e08d";
  font-size: 1.15em;
}`, "",{"version":3,"sources":["webpack://./src/Components/Message/MessageReferences/MessageReferences.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;AACJ;AACI;EACI,gBAAA;EACA,iBAAA;AACR","sourcesContent":[".MessageReferences {\r\n    padding: 0;\r\n\r\n    .accordion .accordion-header:before {\r\n        content: \"\\e08d\";\r\n        font-size: 1.15em;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
