import { IMessage } from "../../../Models/Message";
import MessageBubble from "../MessageBubble";
import MessageFooter from "../MessageFooter";

export interface MessageWrapperProps {
    message: IMessage
    children: React.ReactNode
}


export default function MessageWrapper({ message, children }: MessageWrapperProps) {
    return (
        <div className="Message">
            <MessageBubble messageType={message.type}>
                {children}
                <MessageFooter message={message} />
            </MessageBubble>
        </div>
    )
}
