import { Observable } from "rxjs";
import { AIMessage } from "../Models/Message";
import { BaseAPI } from "./base";
import { getMockFollowUps } from "../Mocks/messages";

export class QueryAPI extends BaseAPI {

    public async query(index:string, query:string): Promise<AIMessage> {
        let payload = {
            index:index,
            query:query,
        }
        
        try {
            let response = await this.post(`${this.apiBase}/query`, payload, "json")
            return new AIMessage(
                response.content,
                response.datetime,
                response
            )
        } catch (error) {
            console.error(`Error in query method: ${error}`);
            return new AIMessage(
                "Sorry, I'm having some trouble answering your question. If this issue persists, please contact the site administrator.",
                new Date()
            )
        }
    }

    public async streamQuery(index:string, query:string): Promise<any> {
        
        try {
            let stream: Observable<string> = await this.post(`${this.apiBase}/query/stream`, {index:index, query:query}, "stream")
            stream.subscribe(x => console.log(JSON.parse(x)))
        } catch (error) {
            console.error(`Error in streamQuery method: ${error}`);
            return new Observable<AIMessage>()
        }
    
    }

    public async getSuggestedQuestions(index:string, query:string): Promise<string[]> {
        if (this.isMock) {
            return this.mockFetch(getMockFollowUps, 3000)
        }
        
        let payload = {
            index:index,
            query:query,
        }
        
        try {
            let response = await this.post(`${this.apiBase}/query/suggest-questions`, payload, "json")
            return response
        } catch (error) {
            console.error(`Error in getSuggestedQuestions method: ${error}`);
            return []
        }
    }
}