import './Navbar.scss';
import { Container, Nav, Navbar, NavDropdown, Form } from 'react-bootstrap';

interface AskMaddiNavbarProps {
    href?: string;
    children: React.ReactNode;
    className?: string;
    variant?: 'light' | 'dark' | string;
    expand?: boolean | string | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
    bg?: string;
    fixed?: 'top' | 'bottom';
    sticky?: 'top';
}

function AskMaddiNavbar({href='/', variant='dark', expand='sm', sticky='top', bg, className, fixed, children}:AskMaddiNavbarProps) {
    return (
    <Navbar 
        collapseOnSelect 
        variant={variant}
        data-bs-theme={variant}
        bg={bg}   
        expand={expand} 
        sticky={sticky}
        fixed={fixed}
        className={ !className ? 'AskMaddiNavbar' : `AskMaddiNavbar ${className}`}
    >
        <Container>
            <Navbar.Brand href={href} />
            <Navbar.Toggle aria-controls="responsive-navbar-nav">
                <div className="btn-mobile-menu">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </Navbar.Toggle>
            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                <Nav className="ms-auto">
                    {children}
                </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>
  );
}


export default AskMaddiNavbar