import { Subject, from, share } from "rxjs";

export const DEFAULT_API_HOST = "https://askmaddi.aderant.com";

export interface BaseAPIOptions {
    apiOrigin?: string;
    accessToken?: string;
    tokenRefreshCallback: () => Promise<string>;
    maxRetries?: number;
}

export abstract class BaseAPI {
    protected apiOrigin: string;
    protected tokenRefreshCallback: () => Promise<string>;
    protected maxRetries: number = 3;
    protected isMock: boolean = false;

    get apiBase() {
        return `${this.apiOrigin}`;
    }

    constructor(options: BaseAPIOptions) {
        this.apiOrigin = options.apiOrigin || DEFAULT_API_HOST;
        this.tokenRefreshCallback = options.tokenRefreshCallback;
        this.maxRetries = options.maxRetries || 3;
        this.isMock = options.apiOrigin === 'mock';
        if (this.isMock) {
            console.debug("Using Mock API");
        }
    }
    
    protected async fetchWithRetries(uri: string, options: RequestInit, retries: number = 3, resultType: "text" | "json" | "stream" = "json") {
        for (let i = 0; i < retries; i++) {
            const response = await fetch(uri, options);
            if (response.ok) {
                switch(resultType) {
                    case "text":
                        return response.text();
                    case "json":
                        return response.json();
                    case "stream":
                        if (!response.body) {
                            throw new Error("Response body is null");
                        }
                    
                        const textDecoder = new TextDecoder();
                        const reader = response.body.getReader();
                    
                        const readStream = async function* () {
                            let buffer = '';
                            while (true) {
                                const readResult = await reader.read();
                                if (readResult.done) break;
                                buffer += textDecoder.decode(readResult.value);
                                const chunks = buffer.split(/(?<!\\)\n/g);
                                buffer = chunks.pop() || ''; // Keep the last partial line in the buffer
                                for (let chunk of chunks) {
                                    if (chunk.trim() !== '') { // Ignore empty strings
                                        try {
                                            yield JSON.parse(chunk);
                                        } catch (err) {
                                            console.error('Failed to parse JSON', err);
                                            console.error('Malformed JSON:', chunk);
                                        }
                                    }
                                }
                            }
                            // Handle any remaining data in the buffer
                            if (buffer.trim() !== '') {
                                try {
                                    yield JSON.parse(buffer);
                                } catch (err) {
                                    console.error('Failed to parse JSON', err);
                                    console.error('Malformed JSON:', buffer);
                                }
                            }
                        };
                    
                        let stream$ = from(readStream());
                        return stream$.pipe(share())
                }
            } else if (response.status !== 403 && response.status !== 500) {
                console.error(`Request failed with status ${response.status}: ${response.statusText}`);
                break;
            }
        }
        throw new Error(`Request failed after ${retries} retries`);
    }
    
    protected async get(uri: string, resultType: "text" | "json" | "stream" = "text") {
        const accessToken = await this.tokenRefreshCallback();
        return this.fetchWithRetries(uri, {
            method: "GET",
            mode: "cors",
            headers: {
                "Authorization": `bearer ${accessToken}`
            },
        }, 3, resultType);
    }
    
    protected async post(uri: string, payload: object, resultType: "text" | "json" | "stream" = "json") {
        const accessToken = await this.tokenRefreshCallback();
        return this.fetchWithRetries(uri, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `bearer ${accessToken}`
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify(payload)
        }, 3, resultType);
    }

    protected async put(uri: string, payload: object, resultType: "text" | "json" | "stream" = "json") {
        const accessToken = await this.tokenRefreshCallback();
        return this.fetchWithRetries(uri, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `bearer ${accessToken}`
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify(payload)
        }, 3, resultType);
    }

    protected async mockFetch(mockCallback: () => any, promiseTimeout: number = 3000): Promise<any> {
        // return the mockCallback promise that resolves in 3 seconds
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(mockCallback());
            }, promiseTimeout);
        });
    }

}

