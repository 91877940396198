// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timestamp {
  font-size: 0.7em;
  font-weight: 325;
  text-align: right;
  opacity: 0.5;
  margin: 0;
  position: absolute;
  right: 25px;
  bottom: 8px;
}

.MessageFooter {
  padding: 0 25px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Message/MessageFooter/MessageFooter.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,gBAAA;EACA,iBAAA;EACA,YAAA;EACA,SAAA;EACA,kBAAA;EACA,WAAA;EACA,WAAA;AACJ;;AAEA;EACI,eAAA;AACJ","sourcesContent":[".timestamp {\r\n    font-size: 0.7em;\r\n    font-weight: 325;\r\n    text-align: right;\r\n    opacity: 0.5;\r\n    margin: 0;\r\n    position: absolute;\r\n    right: 25px;\r\n    bottom: 8px;\r\n}\r\n\r\n.MessageFooter {\r\n    padding: 0 25px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
