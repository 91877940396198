import './InformationIcon.scss';

export interface InformationIconProps {
    version?: 'filled' | 'outlined';
    variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';
}

export default function InformationIcon({version='filled', variant='primary'}:InformationIconProps) {
    switch (version) {
        case 'filled':
            return (
                <div className={`icon InformationIcon filled ${variant}`}>&#xe066;</div>
            )
        case 'outlined':
            return (
                <div className={`icon InformationIcon outlined ${variant}`}>&#xe067;</div>
            )
    }
}