import './Loading.css';

export function LoadingAnimation() {
    return (
        // Please style me with a nice animation 
        // should be a spinner or something
        // should be reusable to display large when whole application is loading
        // and small when just messages or conversations are loading
        <div className="page-loader-container">
            <ul>
                <li>
                <div className="page-loader center"><span></span></div>
                </li>
            </ul>
        </div>
    )
}