import ChatContainer from "../Components/ChatContainer/ChatContainer";


export default function ATO() {
    const indexName = 'sales';
    
    return (
    <div className='maddi-wrapper transparent no-header'>
        <ChatContainer indexName={indexName}></ChatContainer>
    </div>
    )

}