// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-flyout-toggle {
  display: flex;
  min-width: 25px;
  height: 100%;
  height: fit-content;
  z-index: 0;
  position: relative;
  color: purple;
  align-items: center;
  align-self: center;
}

.btn-flyout-toggle:before {
  content: "y";
  font-family: "viglobal";
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.flyout-left.flyout-expanded + .btn-flyout-toggle:before {
  transform: rotate(180deg);
}

.ConversationSelectionMenu.flyout-left:not(.flyout-expanded) .list-group {
  display: none;
}

.btn-flyout-toggle:before {
  display: none;
}

.btn-flyout-toggle svg {
  transform: rotate(-90deg);
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Components/Buttons/FlyoutToggleButton/FlyoutToggleButton.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,eAAA;EACA,YAAA;EACA,mBAAA;EACA,UAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,YAAA;EACA,uBAAA;EACA,aAAA;EACA,WAAA;EACA,mBAAA;EACA,uBAAA;AACJ;;AAEA;EACI,yBAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,yBAAA;EACA,eAAA;AACJ","sourcesContent":[".btn-flyout-toggle {\r\n    display: flex;\r\n    min-width: 25px;\r\n    height: 100%;\r\n    height: fit-content;\r\n    z-index: 0;\r\n    position: relative;\r\n    color: purple;\r\n    align-items: center;\r\n    align-self: center;\r\n}\r\n\r\n.btn-flyout-toggle:before {\r\n    content: \"y\";\r\n    font-family: 'viglobal';\r\n    display: flex;\r\n    width: 100%;\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n\r\n.flyout-left.flyout-expanded + .btn-flyout-toggle:before {\r\n    transform: rotate(180deg);\r\n}\r\n\r\n.ConversationSelectionMenu.flyout-left:not(.flyout-expanded) .list-group {\r\n    display: none;\r\n}\r\n\r\n.btn-flyout-toggle:before {\r\n    display: none;\r\n}\r\n\r\n.btn-flyout-toggle svg {\r\n    transform: rotate(-90deg);\r\n    cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
