import ATO from "./ATO";
import Expert from "./Expert";
import FAQ from "./FAQ";
import ProfessionalServices from "./ProfessionalServices";
import Sales from "./Sales";
import Vi from "./Vi";
import Updates from "./Updates";
import { AskMaddiLoginPage } from "./Login";
import { LoadingPage } from "./Loading";
import { AskMaddiLoginErrorPage } from "./LoginError";


export {
    ATO,
    Expert,
    FAQ,
    ProfessionalServices,
    Sales,
    Vi,
    Updates,
    AskMaddiLoginPage,
    AskMaddiLoginErrorPage,
    LoadingPage
}