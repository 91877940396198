import React from 'react';
import { Accordion, Card, Container, Modal } from 'react-bootstrap';
import Markdown from 'react-markdown';

const questions = [
    {
        title: 'What is askMADDI',
        content: (
            'askMADDI is a powerful AI assistant that gives you direct access to information on Aderant products and processes use a natural language interface.\n\n' +
            '**How askMADDI works**\n\n' +
            '*The Simple Answer*\n\n' +
            'askMADDI uses a large language model (LLM) to understand and respond to your questions.' +
            'The language model replies with a response using the information it already knows as well as relevant Aderant documentation related to your question (returned to you as references).\n\n' +
            '*The Technical Answer*\n\n' +
            'askMADDI uses a transformer-based language model to understand and respond to your questions. ' +
            'The language model learns by “reading” trillions of words that help it pick up on patterns that make up language, which allows it to pick up on and reply to your questions in common language patterns. ' +
            'This means that: \n\n' +
            '- askMADDI can understand and respond to a wide range of questions\n' +
            '- askMADDI understands and responds to questions based on the information it has been trained on as well as the supplied Aderant documentation\n' +
            '- askMADDI answers based on probability and patterns\n' +
            '- askMADDI can make mistakes and get things wrong - it is not perfect\n\n' +
            `**Way's to use askMADDI**\n\n` +
            'Here are some prompt examples you can try out: \n\n' +
            '- What are the steps to add new time to a prebill? \n' +
            '- What are the steps to customize the layout of my EA Pro dashboard?\n' +
            '- What are the different sources for Time Entries and their meanings?\n\n' +
            `**askMADDI isn't human** \n` +
            'It doesn’t have its own thoughts or feelings, even though it might sound like a human. Remember:\n\n' +
            '- askMADDI is a tool that can help you find information\n' +
            '- askMADDI is not a human expert\n' +
            '- askMADDI is not perfect and can make mistakes\n' +
            '- askMADDI is constantly learning and improving\n' +
            '- *askMADDI is not a replacement for human experts*\n'
        ),
    },
    {
        title: 'How to use askMADDI',
        content: (
            'To begin using askMADDI, select an area of expertise that askMADDI is trained on. ' +
            'Once you select the area of expertise, you can begin your conversation with askMADDI by entring your question in the input area and hitting enter. ' +
            'askMADDI will then generate a response to your question using the wealth of information it is trained with. ' +
            'Once askMADDI has responded it will also provide references to relevant documents that it used to answer your question as well as possible follow-up questions. ' +
            'You can click on references listed to view the actual document used to generate the response.  \n\n' +
            'Additionaly, You can select the follow-up questions under the continue your journey section to get more information related to the question you asked.  \n\n' +
            'askMADDI uses the information within a conversation as additional context to generate the best possible response to your questions. Once you are done with your conversation with askMADDI, you can click on the "New Topic" icon to clear the existing conversation and start a new one.  \n\n'            
        )
    },
    {
        title: 'Best Practices for Using askMADDI',
        content: (
            'To get the most out of askMADDI ask clear concise, and specific questions. ' +
            'Ask question using sentences as opposed search engine keywords in order to get the best results with askMADDI. ' +
            'Remember *askMADDI cannot read your mind* so you need to be clear and concise to get  good quality responses. For best results use the following general ' +
            ' guidelines when interacting with askMADDI: \n\n' +
            '- **Ask clear and specific questions**: Clearly state your question or request. Avoid vague or ambiguous language that could lead to misunderstandings.\n' +
            '- **Use natural language**: askMADDI works best when you ask questions in a natural language pattern as opposed to using a typical search engine keyword approach.\n' +
            '- **Provide context**: The more context you provide, the better askMADDI can understand and respond to your question.\n' +
            '- **Focus your questions one at a time**: askMADDI works best when you ask one question at a time. If you have multiple questions, ask them one at a time.\n' +
            '- **Specify how you would like to receive the information**: If you would like your response in a specific format such as a summary, explanation, or a list, make that preference known to askMADDI as part of your question \n' +
            '- **Avoid Assumptions**: Avoid assuming that askMADDI knows what you are thinking or assuming. Provide as much context as possible when asking questions to assist askMADDI understanding your question.\n' +
            '- **Be patient and iterate**: If askMADDI doesn’t understand your question, try rephrasing it or providing more context. If you don’t get the answer you’re looking for, try asking the question in a different way.\n'
        )
    },
    {
        title: 'Why does askMADDI sometime get things wrong?',
        content:
        'Generative AI and all of its possibilities are exciting, but it’s still new. askMADDI will make mistakes. ' +
        `Even though it’s getting better every day, askMADDI can provide inaccurate information. \n\n` +
        '**Double-check information you get from askMADDI**\n\n' +
        'askMADDI has tools to help prevent factually incorrect information from being shared, but it’s not perfect. ' +
        'One way to double-check the information you get from askMADDI is to look at the references provided. ' +
        'These references will show you the documents used to generate the response. \n\n' +
        '**askMADDI can combine information in unexpected ways**\n\n' +
        'askMADDI can combine information from source documents that do not belong together, which can lead to unexpected or incorrect responses. ' +
        'As such, it is important to verify the information you get from askMADDI. \n\n' +
        '**Help make askMADDI better**\n\n' +
        'We are constantly updating askMADDI to improve the overall experience including quality of responses.  As part of the improvement process askMADDI provides users with the ability to '+
        '- Mark good responsees\n' +
        '- Report inaccurate information\n' +
        '- Provide feedback\n' +
        '- Collaborate with the askMADDI team to improve its understanding and knowledge base\n\n' +
        '**Be mindful of how you use askMADDI**\n\n' +
        `askMADDI will use conversations you have to improve future respones for everyone so don't enter anything you wouldn't want a reviewer to see or askMADDI to use`
    },
    {
        title: 'How can I help make askMADDI better?',
        content:
        'By providing feedback, asking new questions, and reporting inaccurate information, you can help askMADDI improve its understanding and knowledge base.',
    }, 
    {
        title: 'How does askMADDI cite its sources?',
        content: (
            'askMADDI, is intended to generate original content and not replicate existing content at length. ' +
            'askMADDI is cites the source documents used to respond to a question as well as links to these documents where applicable. ' +
            'Note the source documents cited are filtered based on relevance but are not guaranteed to be the most up-to-date. '
        )
    },
    {
        title: "Where does askMADDI source its information from?",
        content: (
            "askMADDI sources its information from a wide range of internal and externally available information including Prduct Documentation, Salesforce Articles, and Teams Messages. " + 
            "askMADDI may use past conversations to help generate responses but does not store any personal data from these conversations. " +
            "For askMADDI we use an internal review process to ensure that the information provided is accurate and up to date as possible. Our reviewers validate past conversations and update or author new documents with the ideal content to assist askMADDI in generating responses.  This process is designed to ensure that askMADDI provides the most accurate and relevant information possible as well as extending askMADDI 's knowledge base."
        )
    },
    {
        title: "What is continue your journey?",
        content:(
            "askMADDI has a \"Continue your journey\" feature that provides follow-up questions you may want to ask to get more details on your topic. " +
            "The follow-up questions are generated based on the response askMADDI has provided and are designed to help you get the most out of your interactions with askMADDI." +
            "This feature is designed to help you get the most out of your interactions with askMADDI."
        )
    },
    {
        title: "What is the difference between askMADDI and other AI assistants?",
        content: (
            "askMADDI is designed for internal use at Aderant and is trained on a wide range of Aderant specific information. " +
            "This allows askMADDI to provide more accurate and relevant information to Aderant employees than other AI assistants. " +
            "askMADDI provides references to the Aderant documents used to generate the response and potential follow-up questions to help you get the most out of your interactions." +
            "askMADDI is designed to help in all-things Aderant."
        )
    },
    {
        title: "What are Real-time Responses?",
        content:(
            "When large language models (LLMs) like askMADDI are used to generate responses, the responses are generated one token at a time rather than all at once. " +
            "As this is an iterative process, we have access to the response well before it is finished. " +
            "This allows us to provide real-time responses to the user as the response is being generated. "
        )
    },
    {
        title: "What personal data does askMADDI store?",
        content: (
            "As part of the authentication process with Azure AD, askMADDI creates a record using your username, email address and Azure AD ID for persisting personalized settings. " +            
            "askMADDI does not have access to any personal information when generating responses." + 
            "You personal information is not shared to other users or used to generate responses."
        )
    }
]

export default function FAQ() {
    return (
        <div className='content-wrapper gap-20'>
            <h1 className='gap-5'>Frequently Asked Questions</h1>
            <Accordion className='fixed gap-5'>
                {questions.map((question, index) => (
                    <Accordion.Item key={index} eventKey={index.toString()}>
                    <Accordion.Header>{question.title}</Accordion.Header>
                    <Accordion.Body>
                        <Markdown>
                            {question.content}
                        </Markdown>
                    </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </div>
    );
}

