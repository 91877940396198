import { ISourceNode } from '../../../Models/SourceNode';
import { Accordion } from 'react-bootstrap';
import './MessageReferences.scss';

export interface MessageSourceNodeProps {
    node:ISourceNode
}

export function MessageSourceNode(props:MessageSourceNodeProps) {
    const node = props.node;

    return (
        <li>
            {node.url ? <a href={node.url} target="_blank">{node.name}</a> : <p>{node.name}</p>}
        </li>
    )

}

export interface MessageReferencesProps {
    sourceNodes: Array<ISourceNode>
}

export default function MessageReferences(props:MessageReferencesProps) {
    const sourceNodes = props.sourceNodes;

    return (
        <div className='MessageReferences references'>
            <Accordion flush>
                <Accordion.Header>
                    References
                </Accordion.Header>
                <Accordion.Body>
                    <ul>
                        {sourceNodes.map((node, index) => <MessageSourceNode key={index} node={node}></MessageSourceNode>)}
                    </ul>
                </Accordion.Body>
            </Accordion>
        </div>
    )
}
