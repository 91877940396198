import React, {useState, useEffect, useContext} from 'react';
import { AIMessage, HumanMessage, SystemMessage } from '../Models/Message';
import { useAskMaddiAPI } from './useAskMaddiAPI';


export function useConversationHistory(conversationId: string | undefined, isNewConversation: boolean): [(AIMessage | HumanMessage | SystemMessage)[], boolean, React.Dispatch<React.SetStateAction<(AIMessage | HumanMessage | SystemMessage)[]>>] {
    const askMaddiAPI = useAskMaddiAPI();
    const [history, setHistory] = useState<(AIMessage | HumanMessage | SystemMessage)[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (conversationId && !isNewConversation) {
            const getConversationHistory = async () => {
                setIsLoading(true);
                const history = await askMaddiAPI.Message.getConversationHistory(conversationId);
                setHistory(history);
                setIsLoading(false);
            };
            getConversationHistory();
        }
    }, [conversationId]);

  return [history, isLoading, setHistory];

}