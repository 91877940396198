import './Login.scss';
import { Modal } from "react-bootstrap";
import { AuthenticationServiceContext } from '../Contexts';
import { AuthenticationService } from "../Auth/AuthenticationClient";
import { useContext } from 'react';


export interface AskMaddiLoginPageProps {
}

export function AskMaddiLoginPage() {
    const authenticationService = useContext(AuthenticationServiceContext);

    const handleLogin = () => {
        authenticationService?.login("redirect")
    }

    const handleLogout = () => {
        authenticationService?.logout("redirect")
    }

    return (
        <Modal show={true} centered className='login-wrapper'>
            <div className='logo-brand'></div>
            <div className="card unauth-div p-3 text-center">
                {/* <h1 className="card-title">Aderant AskMaddi</h1> */}
                <h5 className="card-title">Please sign-in to access this application.</h5>
                <button onClick={handleLogin}>Sign In</button>
                {/* <button onClick={handleLogout}>Sign Out</button> */}
            </div>
        </Modal>
    )
}