import './Login.scss';
import { Modal } from "react-bootstrap";

export interface AskMaddiLoginErrorPageProps {
    loginError: Error;
}

export function AskMaddiLoginErrorPage({loginError}:AskMaddiLoginErrorPageProps) {
    console.error(loginError);
    return (
        <Modal show={true} centered className='login-wrapper'>
            <div className='logo-brand'></div>
            <div className="card unauth-div p-3 text-center">
                <h5 className="card-title">
                    An error occurred while trying to log in. <br /> 
                    Please refresh the page and try again. <br /> 
                    If this error continues to occur, please contact the administrator
                </h5>
                <p>{loginError.message}</p>
            </div>
        </Modal>
    )
}