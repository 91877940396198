import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ChatContainer from '../Components/ChatContainer/ChatContainer';

export default function ATO() {
    const indexName = 'ato';

    return (
    <div className='maddi-wrapper transparent no-header'>
        <ChatContainer indexName={indexName}></ChatContainer>
    </div>
    )

}