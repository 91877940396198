import AskMaddiChatContainer from '../Components/ChatContainer/ChatContainer';

export interface ChatPageProps {
    indexName: string;
}

export default function ChatPage({indexName}: ChatPageProps) {    
    return (
        <AskMaddiChatContainer indexName={indexName}/>
    )

}