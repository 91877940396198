import Markdown from 'react-markdown';
import './MessageContent.scss';
import remarkGfm from 'remark-gfm';

export interface MessageContentProps {
  content: string;
  messageType: string;
}

export default function MessageContent({ content }: MessageContentProps) {
  return (
    <div className={`MessageContent`}>
      <Markdown remarkPlugins={[remarkGfm]}>{content}</Markdown>
    </div>
  );
}
