import { IConversation } from "../Models/Conversation";


export function getMockConversation(): IConversation {
    return {
        id: "1234",
        tenantId: "1234",
        userId: "1234",
        datetime: new Date(),
        firstMessageContent: "Mock conversation",
        firstMessageDatetime: new Date(),
        firstMessageId: "1234",
        lastMessageContent: "Mock conversation",
        lastMessageDatetime: new Date(),
        lastMessageId: "1234",
    }
}

const now = new Date();

export function getMockConversations(): IConversation[] {
    return [
        {
            id: "1234",
            tenantId: "1234",
            userId: "1234",
            datetime: new Date(now),
            firstMessageContent: 'What is the "Six Degrees of Separation" concept and how does it relate to network science?',
            firstMessageDatetime: new Date(now),
            firstMessageId: "1234",
            lastMessageContent: "Mock conversation",
            lastMessageDatetime: new Date(now),
            lastMessageId: "1234",
        },
        {
            id: "2345",
            tenantId: "1234",
            userId: "1234",
            datetime: new Date(now.setDate(now.getDate() - 1)),
            firstMessageContent: 'How does the structure of a network (e.g., centralized vs decentralized) impact its resilience to failures or attacks?',
            firstMessageDatetime: new Date(now.setDate(now.getDate() - 1)),
            firstMessageId: "1234",
            lastMessageContent: "Mock conversation",
            lastMessageDatetime: new Date(now.setDate(now.getDate() - 1)),
            lastMessageId: "1234",
        },
        {
            id: "3456",
            tenantId: "1234",
            userId: "1234",
            datetime: new Date(now.setDate(now.getDate() - 2)),
            firstMessageContent: 'Can you explain the concept of "scale-free" networks and provide an example of one?',
            firstMessageDatetime: new Date(now.setDate(now.getDate() - 2)),
            firstMessageId: "1234",
            lastMessageContent: "Mock conversation",
            lastMessageDatetime: new Date(now.setDate(now.getDate() - 2)),
            lastMessageId: "1234",
        },
        {
            id: "4567",
            tenantId: "1234",
            userId: "1234",
            datetime: new Date(now.setDate(now.getDate() - 3)),
            firstMessageContent: 'How can network science be applied to understand and predict the spread of diseases in a population?',
            firstMessageDatetime: new Date(now.setDate(now.getDate() - 3)),
            firstMessageId: "1234",
            lastMessageContent: "Mock conversation",
            lastMessageDatetime: new Date(now.setDate(now.getDate() - 3)),
            lastMessageId: "1234",
        },
        {
            id: "5678",
            tenantId: "1234",
            userId: "1234",
            datetime: new Date(now.setDate(now.getDate() - 4)),
            firstMessageContent: 'In the context of social networks, what is a "clique" and how can it be identified using network science techniques?',
            firstMessageDatetime: new Date(now.setDate(now.getDate() - 4)),
            firstMessageId: "1234",
            lastMessageContent: "Mock conversation",
            lastMessageDatetime: new Date(now.setDate(now.getDate() - 4)),
            lastMessageId: "1234",
        },
    ]
}