import React, { useRef, useEffect } from 'react';

function useAutoScroll(
    scrollTargetRef: HTMLDivElement | null = null,
    mutationTargetRef: HTMLDivElement | null = null,
): [React.MutableRefObject<null | HTMLDivElement>, React.MutableRefObject<null | HTMLDivElement>, boolean, boolean]{
    const scrollListenerRef = useRef<null | HTMLDivElement>(scrollTargetRef);
    const scrollToRef = useRef<null | HTMLDivElement >(mutationTargetRef);
    const [autoScroll, setAutoScroll] = React.useState(true);
    const [canScrollDown, setCanScrollDown] = React.useState(false);

    const isInViewport = (element: HTMLElement | null) => {
        if (!element) return false;
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    };

    useEffect(() => {
        if (scrollListenerRef && scrollListenerRef.current) {
            let lastScrollTop = 0;
            const handleScroll = (event: Event) => {
                if (!scrollListenerRef.current) return;
                console.log('scrolling');
                const { scrollTop, scrollHeight, clientHeight } = scrollListenerRef.current;
                let scrollDirection = scrollTop > lastScrollTop ? "down" : "up";
                lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
                setAutoScroll(scrollDirection === "down" && isInViewport(scrollToRef.current));
                if (isInViewport(scrollToRef.current)) {
                    setCanScrollDown(false);
                }
            };
            
            scrollListenerRef.current.addEventListener('scroll', handleScroll);
            return () => scrollListenerRef?.current?.removeEventListener('scroll', handleScroll);
        }
    }, [scrollListenerRef, scrollToRef]);

    useEffect(() => {
        if (scrollListenerRef && scrollListenerRef.current) {
            const observer = new MutationObserver((mutationsList, observer) => {
                if (autoScroll) {
                    scrollToRef?.current?.scrollIntoView({ behavior: "smooth" });
                } else {
                    setCanScrollDown(true);
                }
            });

            observer.observe(scrollListenerRef.current, {
                childList: true,
                subtree: true,
                characterData: true,
                characterDataOldValue: true,
            });

            return () => observer.disconnect();
        }
    }, [scrollListenerRef.current, scrollToRef.current, autoScroll]);

    return [scrollListenerRef, scrollToRef, autoScroll, canScrollDown];
}

export default useAutoScroll;