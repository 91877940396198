import { NavDropdown } from "react-bootstrap";

export interface IndexSelectionNavDropdownProps {
    title?: string;
    selectedIndex?: string;
    indices: string[];
}

export function IndexSelectionNavDropdown({indices, selectedIndex, title}: IndexSelectionNavDropdownProps) {
    indices.sort();
    return (
        <NavDropdown title={ 'Knowledge Area' || selectedIndex || 'Knowledge Area'} id="IndexSelectionNavDropdown" className="IndexSelectionNavDropdown">
            {indices.map((link, index) => <NavDropdown.Item key={index} href={`/${link}`}>{link}</NavDropdown.Item> )}
        </NavDropdown>
    );
}