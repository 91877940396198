import { CopyButton, CopyButtonProps } from "../Buttons/CopyButton";
import { ControlsWrapper } from "./ControlsWrapper";

export interface CopyToClipboardControlProps extends CopyButtonProps {
}

export function CopyToClipboardControl({ textToCopy, callback }: CopyToClipboardControlProps) {
    return (
        <ControlsWrapper>
            <CopyButton textToCopy={textToCopy} callback={callback} />
        </ControlsWrapper>
    )
}