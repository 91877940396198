import { getMockMessage, getMockMessages } from "../Mocks/messages";
import { HumanMessage, AIMessage, SystemMessage, Message, IMessageRating } from "../Models/Message";
import { ISourceNode } from "../Models/SourceNode";
import { BaseAPI} from "./base";


export class MessageAPI extends BaseAPI {

    public async getConversationHistory(conversationId:string): Promise<(AIMessage | SystemMessage | HumanMessage)[]> {
        if (this.isMock) {
            return this.mockFetch(getMockMessages, 3000);
        }

        try {
            let response = await this.get(`${this.apiBase}/messages/conversation/${conversationId}`, "json");
            return response.map((message:any) => {
                if (message.type === "ai") {
                    return new AIMessage(
                        message.content,
                        message.datetime,
                        message
                    );
                } else if (message.type === "system") {
                    return new SystemMessage(
                        message.content,
                        message.datetime,
                        message
                    );
                } else {
                    return new HumanMessage(
                        message.content,
                        message.datetime,
                        message
                    );
                }
            }).sort((a: Message, b:Message) => {
                return a.datetime.getTime() - b.datetime.getTime();
            });
        } catch (error) {
            console.error(error);
            return [];
        }
    }

    public async rateMessage(messageId:string, rating:IMessageRating): Promise<boolean> {
        if (this.isMock) {
            console.log("Mocking rating message");
            console.log(rating)
            return this.mockFetch(() => true, 3000);
        }

        try {
            await this.post(`${this.apiBase}/messages/${messageId}/rate`, rating, "json");
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    
    
    }
}
