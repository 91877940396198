import './ConversationSelectionMenu.scss';
import React from 'react';
import { IConversation } from "../../Models/Conversation";
import { ListGroup } from "react-bootstrap";
import { FlyoutToggleButton } from '../Buttons/FlyoutToggleButton';
import { ControlsWrapper } from '../Controls/ControlsWrapper';
import ConversationFilter from './ConversationFilter';


export interface ConversationSelectionMenuProps {
    conversations: IConversation[];
    onSelectConversation: (conversation: IConversation) => void;
    selectedConversation?: IConversation;
    maxTitleLength?: number;
    onSearch: (search: string, startDate?: Date, endDate?: Date) => void;
}


export default function ConversationSelectionMenu({ conversations, onSelectConversation, selectedConversation, maxTitleLength = 100, onSearch }: ConversationSelectionMenuProps) {
    const [isExpanded, setIsExpanded] = React.useState(true);

    const handleToggleClick = () => {
        setIsExpanded(!isExpanded);
    }
    
    return (
        <div className="conversation-history-wrapper" hidden>        
            <div className={'ConversationSelectionMenu flyout-left' + (!isExpanded ? ' flyout-expanded' : '')}>
                <ListGroup>
                    <ControlsWrapper className='conversation-filter'>
                        <ConversationFilter onSearch={onSearch}/>
                    </ControlsWrapper>
                    <div className="title-primary">Recently asked questions</div>
                    {conversations.sort((a: any, b: any) => b.datetime - a.datetime).filter(c => !!c.firstMessageContent).map((conversation, index) => 
                    <ListGroup.Item key={index} action active={selectedConversation?.id === conversation.id} onClick={() => onSelectConversation(conversation)}>
                        <div className="ConversationSelectionMenuItem">
                            <div className="ConversationSelectionMenuItem-title">
                                <label>
                                    {
                                    conversation.firstMessageContent && conversation.firstMessageContent.length > maxTitleLength 
                                        ? `${conversation.firstMessageContent?.substring(0, maxTitleLength)}...` 
                                        : conversation.firstMessageContent ?? "Conversation title placeholder"
                                    }
                                </label>
                            </div>
                            <div className="ConversationSelectionMenuItem-datetime">{conversation.datetime.toDateString()}</div>
                        </div>
                    </ListGroup.Item>)}
                </ListGroup>
            </div>
            <FlyoutToggleButton onClick={handleToggleClick} isExpanded={!isExpanded} />
        </div>
    )
}
