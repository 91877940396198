import { useContext } from "react";
import { AuthenticationServiceContext } from "../Contexts";
import { AuthenticationService } from "../Auth/AuthenticationClient";


export function useAskMaddiAuthenticationService(): AuthenticationService {
    const authService = useContext(AuthenticationServiceContext);
    if (!authService) {
        throw new Error('useAskMaddiAuthenticationService must be used within an AuthenticationServiceContextProvider');
    }

    return authService;
}