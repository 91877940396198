import { IAIMessage } from "../../../Models/Message"
import { AIMessageProps } from "./AIMessage"
import MessageContent from "../MessageContent"
import AIMessageWrapper from "./AIMessageWrapper"
import { CopyToClipboardControl } from "../../Controls/CopyToClipboard"

export interface NormalAIMessageProps extends AIMessageProps{
    message: IAIMessage;
}

export default function NormalAIMessage({ message, onFollowUpQuestionClick }: NormalAIMessageProps) {
    return (
        <AIMessageWrapper message={message} onFollowUpQuestionClick={onFollowUpQuestionClick}>
            <CopyToClipboardControl textToCopy={message.content} />
            <MessageContent content={message.content} messageType={message.type} />
        </AIMessageWrapper>
    )
}