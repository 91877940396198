import './Login.scss';
import { LoadingAnimation } from "../Components/Animations/Loading"
import { Modal } from 'react-bootstrap';


export function LoadingPage() {
    return (
        <Modal show={true} centered className='page-loader-wrapper default'>
            <LoadingAnimation />
        </Modal>
    )
}