import './ChatWindowTitle.scss'

export interface ChatWindowTitleProps {
    title:string
}

function ChatWindowTitle({title}:ChatWindowTitleProps) {

    return (
        <div className='ChatWindowTitle'>
            <div className='left'>{title}</div>
            <div className='right'>
                <div className='controls-wrapper'>
                    <div className='btn-fullscreen-toggle'><input type="checkbox"/></div>
                    <div className='btn-detailed-toggle vi-close'></div>
                </div>
            </div>
        </div>
    );
}


export default ChatWindowTitle;