import './Chevron.scss';

export interface ChevronIconProps {
    variant?: "opened" | "closed";
    className?: string;
}

export default function ChevronIcon({ variant = "closed", className = "" }: ChevronIconProps)  {
    return (
        <div className={`icon chevron-${variant} ${className}`}>
            <svg width="24" height="24" version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid">
                <g className="chevron-container">
                    <line className="chevron-line1" x1="10" y1="50" x2="50" y2="50"></line>
                    <line className="chevron-line2" x1="90" y1="50" x2="50" y2="50"></line>
                </g>    
            </svg>
        </div>
    )
}