import React, {useState, useEffect, useContext} from 'react';
import {AskMaddiAPIContext} from '../Contexts/AskMaddiAPIContext';
import { IConversation } from '../Models/Conversation';


export function useUserConversations(userId?: string, indexName?:string): [IConversation[], boolean, React.Dispatch<React.SetStateAction<IConversation[]>>] {
    const askMaddiAPI = useContext(AskMaddiAPIContext);
    const [conversations, setConversations] = useState<IConversation[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(!!userId ? true : false);

    useEffect(() => {
        if (userId) {
            const getConversations = async () => {
                let fetchedConversations = await askMaddiAPI?.Conversation.getUserConversations(userId, indexName);
                setConversations(fetchedConversations ?? []);
                setIsLoading(false);
            }
            getConversations();
        }
    }, [userId, indexName]);

  return [conversations, isLoading, setConversations];
}