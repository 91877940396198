

export const DEFAULT_WELCOME_MESSAGE = `
Hi, I am MADDI, I am your assistant for Aderant products. I am still in training but I would love to help you learn more about Aderant Products. Feel free to ask me any questions like...
* How do I revert a prebill?
* How is EA Pro configured?
* What are the steps to add new time to a prebill?
* What are the steps to customize the layout of my EA Pro dashboard?
* What are the different sources for Time Entries and their meanings?

If you don't get the answer you are looking for, try asking follow-up questions with more context or look at the options in Continue your Journey.
`

export function generatePersonalizedWelcomeMessage(name: string) {
    return (
        (name ? `Hello ${name}! ` : 'Hi, ') +
        'I am MADDI, your personal assistant for Aderant products. I am still in training but I would love to help you learn more about Aderant Products. Feel free to ask me any questions like...\n' +
        '* What are the steps to add new time to a prebill?\n' +
        '* What are the steps to customize the layout of my EA Pro dashboard?\n' +
        '* What are the different sources for Time Entries and their meanings?\n\n' +
        'If you don\'t get the answer you are looking for, try asking follow-up questions with more context or look at the options in Continue your Journey.\n' +
        'For more information, you can also visit the [askMADDI FAQ page](./faq)\n'
    )
}