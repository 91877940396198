import { useContext } from "react";
import { AskMaddiAPIContext } from "../Contexts";
import { AskMaddiAPI } from "../API";


export function useAskMaddiAPI(): AskMaddiAPI {
    const askMaddiAPI = useContext(AskMaddiAPIContext);
    if (!askMaddiAPI) {
        throw new Error('useAskMaddiAPI must be used within an AskMaddiAPIContextProvider');
    }

    return askMaddiAPI;
}