import './ConversationFilter.scss';
import { useState } from 'react';

export interface ConversationFilterProps {
    onSearch: (search: string, startDate?: Date, endDate?: Date) => void;
}

export default function ConversationFilter({onSearch}: ConversationFilterProps) {
    const [startDate, setStartDate] = useState<Date|undefined>();
    const [endDate, setEndDate] = useState<Date|undefined>();
    const [search, setSearch] = useState('');

    const handleSearchOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    }

    const handleStartDateOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStartDate(e.target.value ? new Date(e.target.value) : undefined);
    }

    const handleEndDateOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEndDate(e.target.value ? new Date(e.target.value) : undefined);
    }
    
    const handleSearch = () => {
        onSearch(search, startDate, endDate);
    }

    const handleClearSearch = () => {
        setSearch('');
        setStartDate(undefined);
        setEndDate(undefined);
        onSearch('', undefined, undefined);
    }

    return (
        <div className="ConversationFilter">
            <label>
                Start:<input type='date' value={startDate ? startDate.toISOString().split('T')[0] : ''} onChange={handleStartDateOnChange}/>
            </label>
            <label>
                End:<input type='date' value={endDate ? endDate.toISOString().split('T')[0] : ''} onChange={handleEndDateOnChange}/>
            </label>
            <input type='text' placeholder='Search' value={search} onChange={handleSearchOnChange}/>
            <input type='submit' value='Search' onClick={handleSearch}/>
            <input type='submit' value='Clear' onClick={handleClearSearch}/>
        </div>
    )
}