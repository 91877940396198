import { ChatAPI } from "./ChatAPI";
import { MessageAPI } from "./MessageAPI";
import { QueryAPI } from "./QueryAPI";
import { UserAPI } from "./UserAPI";
import { ConversationAPI } from "./ConversationAPI";
import { BaseAPI, BaseAPIOptions, DEFAULT_API_HOST } from "./base";
import { AuthenticationService } from "../Auth/AuthenticationClient";
import { AgentAPI } from "./AgentAPI";

export class AskMaddiAPI  extends BaseAPI {
    Chat: ChatAPI;
    Message: MessageAPI;
    Query: QueryAPI;
    User: UserAPI;
    Conversation: ConversationAPI;
    Agent: AgentAPI;

    constructor(apiOrigin: string = DEFAULT_API_HOST, authenticationService: AuthenticationService) {
        const options: BaseAPIOptions = {
            apiOrigin: apiOrigin,
            accessToken: "",
            tokenRefreshCallback: () => authenticationService.getAccessTokenSilent()
        }
        super(options);
        this.Chat = new ChatAPI(options);
        this.Message = new MessageAPI(options);
        this.Query = new QueryAPI(options);
        this.User = new UserAPI(options);
        this.Conversation = new ConversationAPI(options);
        this.Agent = new AgentAPI(options);
    }

}
