import './App.css';
import { loginRequest } from './Auth/config';
import { AskMaddiLoginPage } from './Pages/Login';
import { AskMaddiProvider } from './Contexts/Providers/AskMaddiContextProvider';
import Router from './Routers/Router';

// MSAL imports
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";

export interface AppProps {
  children?: React.ReactNode;
  msalInstance: IPublicClientApplication;
}

function App({ msalInstance }: AppProps) {
  return (
    <AskMaddiProvider msalInstance={msalInstance} loginRequest={loginRequest}>
      <AuthenticatedTemplate>
        <Router/>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <AskMaddiLoginPage />
      </UnauthenticatedTemplate>
    </AskMaddiProvider>
  );
}

export default App;