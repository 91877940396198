import './Message.scss';
import { memo } from 'react';
import { 
    IMessage, 
    AIMessage as AIMessageModel, 
    HumanMessage as HumanMessageModel,
    SystemMessage as SystemMessageModel 
} from '../../../Models/Message';
import AIMessage from '../AIMessage';
import HumanMessage from '../HumanMessage';
import SystemMessage from '../SystemMessage';

export interface MessageProps {
    message: IMessage,
    onFollowUpQuestionClick?: (question: string) => void
}

function Message({ message, onFollowUpQuestionClick }: MessageProps) {
    if (message instanceof AIMessageModel) {
        return <AIMessage message={message} onFollowUpQuestionClick={onFollowUpQuestionClick} />
    } else if (message instanceof HumanMessageModel) {
        return <HumanMessage message={message} />
    } else if (message instanceof SystemMessageModel) {
        return <SystemMessage message={message} />
    } else {
        return <div>Coming Soon!</div>
    }
}

export default memo(Message);
