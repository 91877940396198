import { ISourceNode } from "../Models/SourceNode"
import { AIMessage, HumanMessage, IAIMessageChunk, SystemMessage} from "../Models/Message"
import { Observable } from "rxjs"
import { RELEASE_NOTES_MD_TABLE } from "./tables"

const SOURCE_NODES:ISourceNode[][] = [
    [
        {
            "id": "28e06dce-95e6-4543-b029-36b139e8b756",
            "url": "https://aderant.lightning.force.com/lightning/r/Knowledge__kav/kA70y000000XagnCAC/view",
            "name": "Salesforce Knowledge Article - KB15037"
        },
        {
            "id": "41e5ecb9-1e36-46cc-9485-a11596c38d69",
            "url": "https://aderant.lightning.force.com/lightning/r/Knowledge__kav/kA70y000000Xb7RCAS/view",
            "name": "Salesforce Knowledge Article - KB16737"
        },
        {
            "id": "1062bcf5-d134-4956-819d-a58f610f0623",
            "url": "https://chatcrtknowledgeset.blob.core.windows.net/expert/Billing/Documentation/Aderant Expert (8.2 SP2) - Expert Billing Setup Guide - January 2020.pdf",
            "name": "Aderant Expert (8.2 SP2) - Expert Billing Setup Guide - January 2020.pdf"
        },
        {
            "id": "8002b930-7d28-42b0-87cc-42e0321a4f60",
            "url": "https://aderant.lightning.force.com/lightning/r/Knowledge__kav/kA70y000000XagnCAC/view",
            "name": "Salesforce Knowledge Article - KB15037"
        }
    ],
    [
        {
          "id": "aa9ce0b8-5cdf-465b-94ed-3c51f0f9efea",
          "url": "https://aderant.lightning.force.com/lightning/r/Knowledge__kav/kA70y000000XagnCAC/view",
          "name": "Salesforce Knowledge Article - KB15037"
        },
        {
          "id": "fed53614-590a-4ff5-bd2c-26d495b6219f",
          "url": "https://aderant.lightning.force.com/lightning/r/Knowledge__kav/kA70y000000k9rpCAA/view",
          "name": "Salesforce Knowledge Article - KB16600"
        },
        {
          "id": "1edb5dda-411b-4610-a9e0-458280fbb9ca",
          "url": "https://chatcrtknowledgeset.blob.core.windows.net/expert/Billing/Documentation/Aderant Expert (8.2 SP2 EX0010) - Expert Paperless Billing User Guide - October 2022.pdf",
          "name": "Aderant Expert (8.2 SP2 EX0010) - Expert Paperless Billing User Guide - October 2022.pdf"
        },
        {
          "id": "2c6075de-decf-4cbe-a501-dbd696fc802c",
          "url": "https://aderant.lightning.force.com/lightning/r/Knowledge__kav/kA70y000000XZLaCAO/view",
          "name": "Salesforce Knowledge Article - KB10003"
        }
      ],
      [
        {
          "id": "30dd20d6-5c7a-4785-9a1d-fd65ba47fe16",
          "url": "https://chatcrtknowledgeset.blob.core.windows.net/expert/Billing/Documentation/Aderant Expert (8.2 SP2 EX0010) - Expert Paperless Billing User Guide - October 2022.pdf",
          "name": "Aderant Expert (8.2 SP2 EX0010) - Expert Paperless Billing User Guide - October 2022.pdf"
        },
        {
          "id": "ca96a654-f031-49f4-aefc-b5fde009c85e",
          "url": "",
          "name": "Teams Channel Message"
        },
        {
          "id": "83d2dfe4-0ed0-4f2d-b5c1-f8156d66a469",
          "url": "",
          "name": "Teams Channel Message"
        },
        {
          "id": "8685d3a9-2575-4e50-b931-acf8f4db12ed",
          "url": "https://chatcrtknowledgeset.blob.core.windows.net/expert/Matter Management/Documentation/Aderant Expert (8.2 SP2 EX0010) - Expert Entity Manager User Guide - October 2022.pdf",
          "name": "Aderant Expert (8.2 SP2 EX0010) - Expert Entity Manager User Guide - October 2022.pdf"
        }
      ],
      [
        {
          "id": "1071077c-49aa-4be2-9ab6-227523b11912",
          "url": "https://aderant.lightning.force.com/lightning/r/Knowledge__kav/kA70y000000k9rpCAA/view",
          "name": "Salesforce Knowledge Article - KB16600"
        },
        {
          "id": "1ea3a556-dcef-46db-afe1-dd18bd74fb34",
          "url": "https://aderant.lightning.force.com/lightning/r/Knowledge__kav/kA74u000000sY37CAE/view",
          "name": "Salesforce Knowledge Article - KB18065"
        },
        {
          "id": "4aa41e2e-0205-42a6-96f2-2adbf792b8ff",
          "url": "https://aderant.lightning.force.com/lightning/r/Knowledge__kav/kA74u000000oNcrCAE/view",
          "name": "Salesforce Knowledge Article - KB18717"
        },
        {
          "id": "e7b194d4-39e9-4818-8513-4c2236c3babd",
          "url": "https://aderant.lightning.force.com/lightning/r/Knowledge__kav/kA74u000000oNcrCAE/view",
          "name": "Salesforce Knowledge Article - KB18717"
        }
      ]
]

const RESPONSES:string[] = [
  ('To undo any changes made to the prebill, you can follow these steps:\n\n' +
  '1. Navigate to the "Changes" tab.\n' +
  '2. Open the context menu for the change you would like to undo.\n' +
  '3. Tap "Undo Change."\n' +
  '4. At any point, tap "Save" at the bottom of the main prebill screen to save your changes. If you would like to save your changes and allow another user to make further changes to the prebill as part of the same workflow task, tap "Reassign" at the bottom of the screen and select "Reassign."\n' +
  '5. When you have made all required changes to the prebill, tap "Complete" and "Next" at the bottom of the screen to complete the workflow task.\n\n' +
  'The changes will be displayed as markups to the prebill and will be available for the Billing Coordinator to review and accept, reject, or modify on the Billing Desktop.'),
  ('To add a discount to a bill, you can follow *these* steps:\n\n' + 
  '1. Open the bill you want to add a discount to.\n' +
  '2. Navigate to the "Discounts" tab.\n' +
  '3. Click "Add" to create a new discount.\n' +
  '4. Select the type of discount you want to add (e.g., courtesy, volume, or advanced premium/discount).\n' +
  '5. Enter the discount amount or percentage in the appropriate field.\n' +
  '6. If necessary, specify the conditions under which the discount should apply (e.g., a minimum bill amount).\n' +
  '7. Click "Save" to apply the discount to the bill.\n\n' +
  'Please note that the specific steps for adding a discount may vary depending on the version of Aderant you are using and the configuration of your billing system. If you encounter any issues or need further assistance, please consult the Aderant documentation or contact your system administrator.'
  ),
  (
    "Sure, here's a simple Python script that prints out the instructions for reverting a prebill:\n\n" +
    "```python\n" +
    "def revert_prebill_instructions():\n" +
    " instructions = [ \n" +
    '   "Go to Billing | Utilities | Reverse Bill.",\n' +
    '   "In the top right corner of the form, select the option to Reverse to Prebill Status. This action will reverse the bill back to its original prebill status and create a new prebill.",\n' +
    '   "Enter the number of the bill you want to reverse and press the Tab key. The matter or matters contained on the bill will then be displayed.",\n' +
    '   "Please remember, this feature might not be visible to users who do not have the necessary security permissions. To give a user or workgroup access to this feature, go to Data Management | Security. Click on the Application button for Security and then double-click to expand the CMS.net folder. Find Time_Billing | Billing | Processes | Billing Utilities. The Reverse to Prebill Status option must be checked for any users or groups that require this feature.",\n' +
    `   "Also, keep in mind that bills converted from a previous software system, interest bills, and bills that contain anticipated disbursements cannot be reversed to prebill status. If you're trying to reverse a bill that has payments or write-offs, you must first cancel these transactions using the Cash Receipt Cancellation program or the Write Bill-off Cancellation program."\n` +
    " ]\n\n" +
    
    "for i, instruction in enumerate(instructions, 1):\n" +
    ` print(f"Step {i}: {instruction}")\n\n` +

  '# Call the function to print the instructions\n' +
  'revert_prebill_instructions()\n' +
  "``` \n" +
  "This script defines a function `revert_prebill_instructions()` that prints out each instruction on a new line. The instructions are stored in a list, and the `enumerate()` function is used to number each step. The function is then called to print the instructions."
  ),
  (
    'To print a message multiple times in Python, you can use a for loop. Here is an example that prints "I love Maddi" 100 times:\n\n' +
    '```python\n' +
    'for i in range(100):\n' +
    '    print("I love Maddi")\n' +
    '```\n\n' +
    'In this code, `range(100)` generates a sequence of numbers from 0 to 99, and the for loop iterates over this sequence. On each iteration, it executes the `print` statement, which prints the message "I love Maddi". This results in the message being printed 100 times.'
  ),
  (
    '# Header 1\n' +
    '## Header 2\n' +
    '### Header 3\n\n' +
    '* Unordered list item 1\n' +
    '* Unordered list item 2\n\n' +
    '1. Ordered list item 1\n' +
    '2. Ordered list item 2\n\n' +
    '[Google](http://www.google.com)\n\n' +
    '![Logo](https://testmaddi.aderant.com/logo192.png)\n\n' +
    '`inline code`\n\n' +
    '```python\n' +
    'print("code block")\n' +
    '```\n\n' +
    '> This is a blockquote.\n\n' +
    '**bold text**\n\n' +
    '*italic text*\n\n' +
    'These are the basic elements of Markdown syntax. You can use them to format your messages and documents in a clear and organized way. ' +
    'As such we need to make sure that Maddi can handle these elements and display them correctly.'
  ),
  RELEASE_NOTES_MD_TABLE
]


const MESSAGES = [
    new AIMessage(RESPONSES[0], new Date(),  {sourceNodes:SOURCE_NODES[0], _id:"1"}),
    new AIMessage(RESPONSES[1], new Date(),  {sourceNodes:SOURCE_NODES[1], _id:"2"}),
    new AIMessage(RESPONSES[2], new Date(),  {sourceNodes:SOURCE_NODES[2], rating:{rating:4, comments:"This was helpful", isHelpful:"yes"}, _id:"3"}),
    new AIMessage(RESPONSES[3], new Date(),  {sourceNodes:SOURCE_NODES[3], _id:"4"}),
    new AIMessage(RESPONSES[4], new Date(),  {sourceNodes:SOURCE_NODES[0], _id:"5"}),
    new AIMessage(RESPONSES[5], new Date(),  {sourceNodes:SOURCE_NODES[1], _id:"6"}),
  ]

const FOLLOW_UP_MESSAGES = [
    "What is the process for undoing a split in a prebill?\n",
    "What should you do if you want to undo a change made to the prebill?\n",
    "What is a Split Bill?\n",
    "What is a prebill?\n"
]

// Function to generate a random date in the last 30 days
function randomDate() {
  const start = new Date();
  start.setDate(start.getDate() - 30);
  const end = new Date();
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

// Function to generate a random message
function randomMessage() {
  const words = ['Lorem', 'ipsum', 'dolor', 'sit', 'amet', 'consectetur', 'adipiscing', 'elit'];
  const longWords = [...words, ...words, ...words, ...words, ...words, ...words, ...words, ...words, ...words, ...words]; 
  return longWords.sort(() => 0.5 - Math.random()).slice(1, Math.floor(Math.random() * longWords.length)).join(' ');
}

export function getMockMessage(input?:string): AIMessage {
    // determine if input is a number between 0 and length of MESSAGES array
    let index = parseInt(input || "");
    if (index >= 0 && index < MESSAGES.length) {
        return MESSAGES[index];
    }

    // get a random value from MESSAGES array 
    let randomIndex = Math.floor(Math.random() * MESSAGES.length);
    return MESSAGES[randomIndex];
}

export function getMockMessages(): (SystemMessage | AIMessage | HumanMessage)[] {
    let messageCount = Math.ceil(Math.random() * 10);
    let messages = [];
    for (let i = 0; i < messageCount; i++) {
        messages.push(new AIMessage(randomMessage(), randomDate(), {sourceNodes:[], _id:i.toString()}));
    }

    return messages;
}

export function getMockFollowUps(): string[] {
    return FOLLOW_UP_MESSAGES
}

function splitIntoNParts(str:string, n:number) {
  const chunkSize = Math.ceil(str.length / n);
  const chunks = [];

  for (let i = 0; i < n; i++) {
    const start = i * chunkSize;
    const end = start + chunkSize;
    const chunk = str.slice(start, end);
    chunks.push(chunk);
  }

  return chunks;
}

export function getMockMessageStream(input?:string): Observable<IAIMessageChunk> {
  // setup the inital metadata 
  let initialMetadata: IAIMessageChunk = {
    _id: (Math.random() * 100).toString(),
    userId: "123",
    tenantId: "456",
    conversationId: "789",
  }
  let finalMetadata: IAIMessageChunk = {
    metadata: {
      time: 33,
    },
  }
  let sourceNodes:IAIMessageChunk = {sourceNodes: SOURCE_NODES[Math.floor(Math.random() * SOURCE_NODES.length)]};
  
  // split the content into chunks
  let contentArray = splitIntoNParts(getMockMessage(input).content, 250);
  let chunks = contentArray.map((word, index) => {
    let chunk:IAIMessageChunk = {
      content: word,
    }
    return chunk;
  })

  // create a new observable 
  return new Observable<IAIMessageChunk>((observer) => {
    let chunkTime = 10;
    let totalTime = 100;

    const incrementTime = () => {
      totalTime += Math.floor(Math.random() * chunkTime);
      return totalTime;
    }

    // send the initial metadata
    setTimeout(() => observer.next(initialMetadata), incrementTime());
  
    // send the source nodes
    setTimeout(() => observer.next(sourceNodes), incrementTime());
    
    [...Array(20)].map((_, i) => incrementTime());
  
    // send the chunks
    chunks.forEach((chunk) => {
      chunk.content = chunk.content;
      setTimeout(() => observer.next(chunk), incrementTime());
    })
  
    // send the final metadata
    setTimeout(() => observer.next(finalMetadata), incrementTime());
  
    // complete the observable
    setTimeout(() => observer.complete(), incrementTime());
  });
}