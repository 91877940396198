import { useEffect, useState } from "react";
import { IMessageRating } from "../../../Models/Message";


export interface MessageRatingFeedbackFormProps {
    isHelpful: "yes" | "no" | undefined,
    onSubmit: (rating : IMessageRating) => void,
    hidden?: boolean,
}

const UNHELPFUL_REASON_OPTIONS: any[] = [
    {
        reason:"irrelevant",
        comments: "The Information is irrelevant or It did not answer my question."
    },
    {
        reason:"outdated",
        comments:"The information is outdated."
    },
    {
        reason:"wrong",
        comments:"The information is factually wrong."
    }
]

const HELPFUL_REASON_OPTIONS: any[] = []
const DEFAULT_RATING = 3;

export function MessageRatingFeedbackForm({ isHelpful, onSubmit, hidden = false }: MessageRatingFeedbackFormProps) {
    const [selectedReason, setSelectedReason] = useState<string | undefined>(undefined);
    const [comments, setComments] = useState<string | undefined>(undefined);
    const [rating, setRating] = useState<number | undefined>(DEFAULT_RATING);
    const [canSubmit, setCanSubmit] = useState<boolean>(false);

    const validateForm = () => {
        if (isHelpful == "yes") {
            setCanSubmit(rating != undefined);
        } else {
            setCanSubmit(selectedReason != undefined && (selectedReason != "other" || comments != undefined) && rating != undefined);
        }
    }

    const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedReason(event.target.value);
    }

    const handleCommentsChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setComments(event.target.value);
    }

    const handleRatingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRating(parseInt(event.target.value));
    }

    const handleRatingSubmit = () => {
        onSubmit({
            isHelpful: isHelpful,
            reason: isHelpful === "no" ? selectedReason : "",
            comments: isHelpful === "no" ? comments : "",
            rating: rating
        });
    }

    useEffect(() => {
        validateForm();
    }, [isHelpful, selectedReason, comments, rating, validateForm])

    return (
        <div className="MessageRatingFeedbackForm" hidden={hidden}>
            <form>
            <div className='message-rating-selection-container'>
                    <label>On a scale of 1-5 how likely are you to recommend this answer to other users? </label>
                    <div className="custom-radio-group white elv-10">                        
                        {[...Array(5)].map((_, i) => (
                            <label key={i} className="custom">
                                <input type="radio" name="rating" value={i + 1} onChange={handleRatingChange} defaultChecked={i+1 === DEFAULT_RATING}/>
                                <div className="value"><span>{i + 1}</span></div>
                            </label>
                        ))}
                    </div> 
                </div>
                <div className="message-rating-reason-radio-group-container" hidden={isHelpful == "yes"}>
                    <label>Why was the response message not helpful?</label>
                    <div className="message-rating-reason-options">
                        {UNHELPFUL_REASON_OPTIONS.map((option, index) => (
                            <div className="option-item radio" key={option.reason}>
                                <input type="radio" id={option.reason} name="reason" value={option.reason} onChange={handleReasonChange} checked={selectedReason === option.reason}/>
                                <label className="radio-label" htmlFor={option.reason}>{option.comments}</label>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="message-rating-reason-comment option-item">
                    <textarea placeholder="Please provide additional feedback" value={comments} onChange={handleCommentsChange} />
                </div>
                <button className="message-rating-submit-btn button-primary default square" type="button" onClick={handleRatingSubmit} disabled={!canSubmit}>Submit</button>
            </form>
        </div>
    )

}