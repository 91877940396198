import './SystemMessage.scss';
import { ISystemMessage } from '../../../Models/Message';
import MessageWrapper from '../MessageWrapper';
import MessageContent from '../MessageContent';

export interface SystemMessageProps {
    message: ISystemMessage;
}

export default function SystemMessage({ message }: SystemMessageProps) {
    return (
        <MessageWrapper message={message}>
            <MessageContent content={message.content} messageType={message.type}/>
        </MessageWrapper>
    )
}