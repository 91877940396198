import { IMessage } from '../../../Models/Message';
import './MessageFooter.scss';

export interface MessageFooterProps {
    message: IMessage;
}


export default function MessageFooter({ message }: MessageFooterProps) {

    // format a Date object into a timestamp such that 
    // Oct 20 2023 13:29:09 GMT-0400 (Eastern Daylight Time) --> 20 Oct, 1:29 pm
    function formatDate(date: Date): string {
        return date.toLocaleString('en-IN', {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        });
    };

    return (
        <div className='MessageFooter'>
            <div>
                <div className='timestamp'>
                    {formatDate(message.datetime)}
                </div>
            </div>
        </div>
    )

}
