import './CopyButton.scss';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

export interface CopyButtonProps {
    textToCopy: string;
    callback?: () => void;
}

export const CopyButton: React.FC<CopyButtonProps> = ({ textToCopy, callback }) => {

    const [isClicked, setIsClicked] = useState(false);

    const handleClick = () => {
        navigator.clipboard.writeText(textToCopy);
        setIsClicked(true);
        if (callback) {
            callback();
        }
        setTimeout(() => setIsClicked(false), 2000); // Reset isClicked after 2 seconds
    };

    return (
        <Button variant='' onClick={handleClick} className={isClicked ? 'copy-btn-clicked' : 'copy-btn'}/>
    );
};