import { BaseAPI } from "./base";
import { getMockUser } from "../Mocks/user";
import { IUser, IUserSettings } from "../Models/User";
import { InteractionRequiredAuthError } from "@azure/msal-browser";


export class UserAPI extends BaseAPI {

    public async exists(username:string): Promise<boolean> {
        let user = await this.getUserByUsername(username);
        return !!user;
    }

    public async getUserById(id:string): Promise<IUser | undefined> {
        if (this.isMock) {
            return this.mockFetch(getMockUser, 2000);
        }

        try {
            let response = await this.get(`${this.apiBase}/users/${id}`, 'json')
            return response;
        } catch (error) {
            console.error(`Error in getUserById method: ${error}`);
            return undefined;
        }
    }

    public async getUserByUsername(username:string): Promise<IUser | undefined> {
        if (this.isMock) {
            return this.mockFetch(getMockUser, 2000);
        }

        try {
            let response = await this.get(`${this.apiBase}/users/search/${username}`, 'json')
            response.id = response._id;
            return response;
        } catch (error) {
            console.error(`Error in getUserByUsername method: ${error}`);
            return undefined;
        }
    }

    public async updateUser(id:string, user:IUser): Promise<boolean> {
        if (this.isMock) {
            console.log("Mocking updateUser")
            return this.mockFetch(() => true, 2000);
        }

        try {
            let response = await this.put(`${this.apiBase}/users/${id}`, user, "text")
            return response != null && response != undefined;
        } catch (error) {
            console.error(`Error in updateUser method: ${error}`);
            return false;
        }
    }

    public async updateUserSettings(id:string, settings:IUserSettings): Promise<boolean> {
        if (this.isMock) {
            console.log("Mocking updateUserSettings")
            return this.mockFetch(() => true, 2000);
        }

        try {
            let response = await this.put(`${this.apiBase}/users/${id}/settings`, settings, "text")
            return response != null && response != undefined;
        } catch (error) {
            console.error(`Error in updateUserSettings method: ${error}`);
            return false;
        }
    }

    public async signupWithAzureAD(): Promise<boolean> {
        if (this.isMock) {
            return true
        }

        try {
            let response = await this.post(`${this.apiBase}/users/signup/azure`, {}, "text")
            return response != null && response != undefined;
        } catch (error) {
            console.error(`Error in signupWithAzure method: ${error}`);
            return false
        }
    
    }

    public async aderantIdentitySignupSignin(): Promise<IUser | Error | undefined> {
        if (this.isMock) {
            return this.mockFetch(getMockUser, 2000);
        }

        try {
            let response = await this.get(`${this.apiBase}/users/aderant_identity_signup_signin/`, "json")
            response.id = response._id;
            return response;
        } catch (error) {
            console.error(`Error in aderantIdentitySignupSignin method: ${error}`);
            if(error instanceof Error){
                return error;
            }
            
            return undefined;
        }    
    }
}