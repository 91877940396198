// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
  display: flex;
  justify-content: center;
  height: calc(100vh - 75px);
  max-height: calc(100vh - 75px);
  max-width: 100vw;
  position: fixed;
}

.App-content {
  min-height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  width: 100%;
  height: calc(100vh - 75px);
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,0BAA0B;EAC1B,8BAA8B;EAC9B,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;EACZ,WAAW;EACX,0BAA0B;AAC5B","sourcesContent":[".App {\r\n  text-align: center;\r\n  display: flex;\r\n  justify-content: center;\r\n  height: calc(100vh - 75px);\r\n  max-height: calc(100vh - 75px);\r\n  max-width: 100vw;\r\n  position: fixed;\r\n}\r\n\r\n.App-content {\r\n  min-height: 90%;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  color: white;\r\n  width: 100%;\r\n  height: calc(100vh - 75px);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
