import { Observable, Subject } from "rxjs";
import { ISourceNode } from "./SourceNode";

export interface IMessageRating {
    isHelpful?: "yes" | "no";
    reason?: string;
    comments?: string;
    rating?: number;
}

export interface IMessageMetadata {
    _id?: string;
    userId?:string;
    tenantId?:string;
    conversationId?: string;
    datetime?: Date;
    tags?: string[];
    metadata?: any;
}

export interface IHumanMessageMetadata extends IMessageMetadata {
    responseId?: string;
}

export interface IAIMessageMetadata extends IMessageMetadata {
    parentId?: string;
    sourceNodes?: ISourceNode[];
    source_nodes?: ISourceNode[];
    followUpQuestions?: string[];
    rating?: IMessageRating;
}

export interface IMessage {
    _id?: string;
    userId?:string;
    tenantId?:string;
    conversationId?: string;
    type: "ai" | "human" | "system";
    content: string;
    datetime: Date;
    tags?: string[];
    metadata?: any;
}

export interface IHumanMessage extends IMessage {
    type: "human";
    responseId?: string;
}

export interface IAIMessage extends IMessage {
    type: "ai";
    parentId?: string;
    sourceNodes?: ISourceNode[];
    followUpQuestions?: string[];
    rating?: IMessageRating;
}

export interface IStreamedAIMessage extends IAIMessage {
    stream$: Observable<IAIMessageChunk>;
    complete$: Subject<boolean>;
    isStopped: boolean;
}

export interface ISystemMessage extends IMessage {
    type: "system";
}

export interface IAIMessageChunk extends IMessageChunk {
    parentId?: string;
    sourceNodes?: ISourceNode[];
    source_nodes?: ISourceNode[];
    followUpQuestions?: string[];
    rating?: IMessageRating;
}

export interface IMessageChunk {
    _id?: string;
    userId?:string;
    tenantId?:string;
    conversationId?: string;
    type?: "ai" | "human" | "system";
    content?: string;
    datetime?: Date;
    tags?: string[];
    metadata?: any;
}

export class Message implements IMessage {
    public type: "ai" | "human" | "system";
    public content: string;
    public datetime: Date;
    public tags?: string[];
    public metadata?: any;
    public _id?: string;
    public userId?:string;
    public tenantId?:string;
    public conversationId?: string;

    constructor(type: "ai" | "human" | "system", content: string, datetime: Date, metadata?:IMessageMetadata) {
        this.type = type;
        this.content = content;
        this.tags = metadata?.tags;
        this.metadata = metadata?.metadata;
        this._id = metadata?._id;
        this.userId = metadata?.userId;
        this.tenantId = metadata?.tenantId;
        this.conversationId = metadata?.conversationId;
        this.datetime = datetime instanceof Date ? datetime : new Date(datetime);
    }
}

export class HumanMessage extends Message implements IHumanMessage {
    public type: "human" = "human";
    public responseId?: string;

    constructor(content: string, datetime: Date, metadata?:IHumanMessageMetadata) {
        super("human", content, datetime, metadata);
        this.responseId = metadata?.responseId;
    }
}

export class AIMessage extends Message implements IAIMessage {
    public type: "ai" = "ai";
    public parentId?: string;
    public sourceNodes?: ISourceNode[];
    public followUpQuestions?: string[];
    public rating?: IMessageRating;

    constructor(content: string, datetime: Date, metadata?:IAIMessageMetadata) {
        super("ai", content, datetime, metadata);
        this.parentId = metadata?.parentId;
        this.sourceNodes = metadata?.sourceNodes ?? metadata?.source_nodes;
        this.followUpQuestions = metadata?.followUpQuestions;
        this.rating = metadata?.rating;
    }
}

export class ObservableAIMessage extends AIMessage implements IStreamedAIMessage {
    stream$: Observable<IAIMessageChunk>;
    complete$: Subject<boolean> = new Subject<boolean>();
    isStopped: boolean = false;

    constructor(data$: Observable<IAIMessageChunk>) {
        super('', new Date());
        this.stream$ = data$;
        this.subscribe();
    }

    private subscribe() {
        this.stream$.subscribe(
            {
                next: (data) => {
                    this.content += data.content ? data.content : "";
                    this.datetime = data.datetime || this.datetime;
                    this.metadata = data.metadata;
                    this._id = this._id || data._id;
                    this.userId = this.userId || data.userId;
                    this.tenantId = this.tenantId || data.tenantId;
                    this.conversationId = this.conversationId || data.conversationId;
                    this.parentId = this.parentId || data.parentId;
                    this.sourceNodes = this.sourceNodes && this.sourceNodes?.length > 0 ? this.sourceNodes : (data.sourceNodes ?? data.source_nodes ?? []);
                    this.followUpQuestions = this.followUpQuestions || data.followUpQuestions;
                    this.rating = this.rating || data.rating;
                },
                error: (err) => {
                    console.error(err);
                },
                complete: () => {
                    this.complete$.complete();
                    this.isStopped = true;
                }
            }
        );
    }
}

export class SystemMessage extends Message implements ISystemMessage {
    public type: "system" = "system";
    constructor(content: string, datetime: Date, metadata?:IMessageMetadata) {
        super("system", content, datetime, metadata);
    }
}
