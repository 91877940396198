import { BaseAPI } from "./base";
import { getMockConversation, getMockConversations } from "../Mocks/conversations";
import { IConversation } from "../Models/Conversation";


export class ConversationAPI extends BaseAPI {
    
    public async getUserConversations(userId: string, indexName?: string, searchTerm?:string, start?: Date, end?: Date): Promise<IConversation[]> {
        if (this.isMock) {
            return this.mockFetch(getMockConversations, 3000)
        }
        
        try {
            let uriBase = `${this.apiBase}/conversations/user/${userId}`;
            let queryParams = [];

            if (indexName) {
                queryParams.push(`indexName=${indexName}`);
            }
            if (searchTerm) {
                queryParams.push(`searchTerm=${searchTerm}`);
            }
            if (start) {
                queryParams.push(`start=${start.toISOString()}`);
            }
            if (end) {
                queryParams.push(`end=${end.toISOString()}`);
            }

            if (queryParams.length > 0) {
                uriBase += `?${queryParams.join('&')}`;
            }

            let response = await this.get(uriBase, "json");
            response.forEach((conversation: any) => {
                conversation.id = conversation._id;
                conversation.datetime = new Date(conversation.datetime);
            });
            return response.sort((a: any, b: any) => b.datetime - a.datetime);
        } catch (error) {
            console.error(`Error in getUsersConversations method: ${error}`);
            return [];
        }
    }

    public async createConversation(conversation: IConversation): Promise<string> {
        if (this.isMock) {
            return this.mockFetch(() => {
                getMockConversation();
                // return random number between 1 and 1000
                return Math.floor(Math.random() * 1000).toString();
            }, 3000)
        }

        try {
            let response = await this.post(`${this.apiBase}/conversations`, conversation, "json")
            return response;
        } catch (error) {
            console.error(`Error in createConversation method: ${error}`);
            return "";
        }
    }


}