import AskMaddiNavbar, {
    HelpNavDropdown, 
    ChatSettingsNavDropdown,
    CurrentUserNavDropdown,
    IndexSelectionNavDropdown,
    HomeNav
} from "../Components/Navbar";
import { Outlet, useLocation } from "react-router-dom";

export interface ChatAppLayoutProps {
    products: Array<string>;
}

export function ChatAppLayout({products}: ChatAppLayoutProps) {
    const location = useLocation();
    // get the current app from the url. If it is not in IMPLEMENTED_LINKS, default to 'Select an Index'
    let currentApp = location.pathname.split('/')[1] || 'Expert';
    if (!products.includes(currentApp)) {
        currentApp = 'Select an Index';
    }

    return (
        <>
            <AskMaddiNavbar>
                <HomeNav/>
                <IndexSelectionNavDropdown selectedIndex={currentApp} indices={products} />
                <HelpNavDropdown/>
                <CurrentUserNavDropdown />
                <ChatSettingsNavDropdown/>
            </AskMaddiNavbar>
            <div className="App">
                <header className="App-content">
                    <Outlet></Outlet>
                </header>
            </div>
        </>
    )
}