import { Configuration, PopupRequest, RedirectRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_AZURE_CLIENT_ID || "",
        authority: process.env.REACT_APP_AZURE_AUTHORITY || "",
        redirectUri:  '/',
        postLogoutRedirectUri: '/',
        knownAuthorities: [ 'login.microsoftonline.com', 'aderantumtest.b2clogin.com', 'aderantidentitydev.b2clogin.com' ]
    },
    cache: {
        cacheLocation: "localStorage",
        temporaryCacheLocation: "localStorage",
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
//Test scopes
export const loginRequest: PopupRequest = {
    scopes: [ 
        'https://aderantumtest.onmicrosoft.com/AskMaddi/AskMaddi.User',
        'https://aderantumtest.onmicrosoft.com/AskMaddi/AskMaddi.Admin'
    ]
};

//Dev scopes
// export const loginRequest: PopupRequest = {
//     scopes: [ 
//         'https://aderantidentitydev.onmicrosoft.com/AskMaddi/AskMaddi.User',
//         'https://aderantidentitydev.onmicrosoft.com/AskMaddi/AskMaddi.Admin'
//     ]
// };

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};