import { IAIMessage } from "../../../Models/Message"
import MessageWrapper from "../MessageWrapper"
import AIMessageMetadata from "../AIMessageMetadata"

export interface AIMessageWrapperProps {
    message: IAIMessage
    children?: React.ReactNode,
    onFollowUpQuestionClick?: (question: string) => void
}

export default function AIMessageWrapper({ message, children, onFollowUpQuestionClick }: AIMessageWrapperProps) {
    return (
        <MessageWrapper message={message}>
            {children}
            <AIMessageMetadata message={message} onFollowUpQuestionClick={onFollowUpQuestionClick} />
        </MessageWrapper>
    )
}